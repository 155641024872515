<script setup lang="ts">
import MultiSelectWithConfirm from "@/components/MultiSelectWithConfirm.vue"
import VarsityInlineConfirm from "@/components/VarsityInlineConfirm.vue"
import VarsityInlineConfirmButton from "@/components/VarsityInlineConfirmButton.vue"
import VarsityInlineConfirmGroup from "@/components/VarsityInlineConfirmGroup.vue"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { createInlineConfirmCollection } from "@/composables/createInlineConfirm"
import { type Issue } from "@/model/tasks/issue"
import { useHttpGet } from "@/vf"
import { ref } from "vue"
import { Multiselect as VueMultiselect } from "vue-multiselect"

const props = defineProps<{
    issue: Issue
}>()
const emit = defineEmits<{
    (event: "update"): void
}>()

const http = useLegacyHttpClient()

type AccountSearchResultItem = {
    id: string
    label: string
    name: string
    primaryAccountNumber?: string
}

async function search(
    searchQuery: string,
    type: "organization" | "user" | "staff_member",
): Promise<AccountSearchResultItem[]> {
    return (
        (await http.get(`tasks/issue/${props.issue.id}/search-participant`, { searchQuery, type })).content as any[]
    ).map(item => {
        let label = item.name

        if (item.primaryAccountNumber) {
            label += ` (${item.primaryAccountNumber})`
        }

        return { ...item, label }
    })
}

/**
 * List
 */
const { content: participants, refresh } = await useHttpGet<AccountSearchResultItem[]>(
    `tasks/issue/${props.issue.id}/participants`,
    true,
)

/**
 * Search organizations
 */
const selectedOrganizationToAdd = ref<AccountSearchResultItem>()
const availableOrganizations = ref<AccountSearchResultItem[]>([])

async function searchOrganizations(searchQuery: string) {
    availableOrganizations.value = await search(searchQuery, "organization")
}

/**
 * Search users
 */
const selectedUserToAdd = ref()
const selectedStaffMemberToAdd = ref()
const availableUsers = ref<AccountSearchResultItem[]>([])
const availableStaffMembers = ref<AccountSearchResultItem[]>([])

async function searchUsers(searchQuery: string) {
    availableUsers.value = await search(searchQuery, "user")
}

async function searchStaffMembers(searchQuery: string) {
    availableStaffMembers.value = await search(searchQuery, "staff_member")
}

/**
 * Add
 */
async function addParticipant(accountId: string | undefined) {
    await http.post(`tasks/issue/${props.issue.id}/add-participant`, { accountId })
    selectedOrganizationToAdd.value = undefined
    selectedUserToAdd.value = null
    selectedStaffMemberToAdd.value = null
    await refresh()
    emit("update")
}

/**
 * Delete
 */
const inlineDelete = createInlineConfirmCollection(key => ({
    async action() {
        await http.delete(`tasks/issue/${props.issue.id}/participant/${key}`)
    },
    async afterAction() {
        await refresh()
        emit("update")
    },
}))

const productGroups = (await http.get("tasks/product-group/")).content
const selectedProductGroupId = ref(props.issue.productGroup?.id)

async function saveProductGroup() {
    await http.post(`tasks/issue/${props.issue.id}/product-group`, { productGroupId: selectedProductGroupId.value })
}

const issueGroups = (await http.get("tasks/issue-group")).content
const selectedIssueGroupId = ref(props.issue.issueGroup?.id)

async function saveIssueGroup() {
    await http.post(`tasks/issue/${props.issue.id}/issue-group`, { issueGroupId: selectedIssueGroupId.value })
}
</script>

<template>
    <div>
        <div>{{ $t("@tasks:issue.add_participant.text") }}</div>

        <div class="d-flex mt-3">
            <div style="width: 300px" class="mr-5">
                <MultiSelectWithConfirm
                    @selected="addParticipant(selectedOrganizationToAdd?.id)"
                    @clear="selectedOrganizationToAdd = undefined"
                    :disabled="!selectedOrganizationToAdd"
                >
                    <VueMultiselect
                        v-model="selectedOrganizationToAdd"
                        :options="availableOrganizations"
                        :placeholder="$t('@tasks:issue.add_participant.add_organization')"
                        label="label"
                        @searchChange="searchOrganizations"
                    >
                        <template #noOptions>{{ $t("@tasks:issue.add_participant.enter_search") }}</template>
                        <template #noResult>{{ $t("@tasks:issue.add_participant.no_results") }}</template>
                    </VueMultiselect>
                </MultiSelectWithConfirm>
            </div>

            <div style="width: 300px" class="mr-5">
                <MultiSelectWithConfirm
                    @selected="addParticipant(selectedUserToAdd?.id)"
                    @clear="selectedUserToAdd = null"
                    :disabled="!selectedUserToAdd"
                >
                    <VueMultiselect
                        v-model="selectedUserToAdd"
                        :options="availableUsers"
                        :placeholder="$t('@tasks:issue.add_participant.add_user')"
                        label="label"
                        @searchChange="searchUsers"
                    >
                        <template #noOptions>{{ $t("@tasks:issue.add_participant.enter_search") }}</template>
                        <template #noResult>{{ $t("@tasks:issue.add_participant.no_results") }}</template>
                    </VueMultiselect>
                </MultiSelectWithConfirm>
            </div>

            <div style="width: 300px">
                <MultiSelectWithConfirm
                    @selected="addParticipant(selectedStaffMemberToAdd?.id)"
                    @clear="selectedStaffMemberToAdd = null"
                    :disabled="!selectedStaffMemberToAdd"
                >
                    <VueMultiselect
                        v-model="selectedStaffMemberToAdd"
                        :options="availableStaffMembers"
                        :placeholder="$t('@tasks:issue.add_participant.add_staff')"
                        label="label"
                        @searchChange="searchStaffMembers"
                    >
                        <template #noOptions>{{ $t("@tasks:issue.add_participant.enter_search") }}</template>
                        <template #noResult>{{ $t("@tasks:issue.add_participant.no_results") }}</template>
                    </VueMultiselect>
                </MultiSelectWithConfirm>
            </div>
        </div>

        <VarsityInlineConfirmGroup>
            <table class="table mt-3">
                <thead>
                    <tr>
                        <th>{{ $t("@tasks:issue.participant.name") }}</th>
                        <th>{{ $t("@tasks:issue.participant.accountNumber") }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="participant of participants" :key="participant.id">
                        <tr>
                            <td>{{ participant.name }}</td>
                            <td>{{ participant.primaryAccountNumber }}</td>
                            <td class="text-right pb-0">
                                <VarsityInlineConfirmButton :controller="inlineDelete(participant.id)">
                                    {{ $t("@tasks:issue.participant.remove") }}
                                </VarsityInlineConfirmButton>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" class="p-0">
                                <div v-animate-show="inlineDelete(participant.id).isOpen">
                                    <VarsityInlineConfirm :controller="inlineDelete(participant.id)">
                                        <template #confirmation>
                                            {{ $t("@tasks:issue.participant.remove_confirm") }}
                                        </template>
                                        <template #success>
                                            {{ $t("@tasks:issue.participant.remove_success") }}
                                        </template>
                                    </VarsityInlineConfirm>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </VarsityInlineConfirmGroup>
        <div class="d-flex mt-3">
            <div style="width: 300px" class="mr-5" v-if="issueGroups.length > 0">
                <div class="form-group">
                    <label for="issueGroup">{{ $t("@tasks:issue.issue_group") }}:</label>
                    <select
                        v-model="selectedIssueGroupId"
                        id="issueGroup"
                        class="form-control form-inline w-auto"
                        @change="saveIssueGroup"
                    >
                        <option :value="null"></option>
                        <option v-for="issueGroup in issueGroups" :key="issueGroup.id" :value="issueGroup.id">
                            <template v-for="i in issueGroup.indent" :key="i">&nbsp;&nbsp;</template>
                            {{ issueGroup.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div style="width: 300px" class="mr-5">
                <div class="form-group">
                    <label for="productGroup">{{ $t("@tasks:issue.product_group") }}:</label>
                    <select
                        v-model="selectedProductGroupId"
                        id="productGroup"
                        class="form-control form-inline w-auto"
                        @change="saveProductGroup"
                    >
                        <option :value="null"></option>
                        <option v-for="productGroup in productGroups" :key="productGroup.id" :value="productGroup.id">
                            <template v-for="i in productGroup.indent" :key="i">&nbsp;&nbsp;</template>
                            {{ productGroup.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div style="width: 300px" class="mr-5"></div>
        </div>
    </div>
</template>
