export interface DelayedAction {
    start: () => void
    stop: () => void
    action: () => void
}

export function useDelayedAction(delay: number, action: () => void, startImmediately: boolean = false): DelayedAction {
    let timeout: number | null = null

    const start = () => {
        stop()
        timeout = setTimeout(action, delay)
    }

    const stop = () => {
        if (!timeout) {
            return
        }

        clearTimeout(timeout)
        timeout = null
    }

    if (startImmediately) {
        start()
    }

    return Object.freeze({ start, stop, action } as const)
}
