<script setup lang="ts">
import type { CmsPagePart } from "@/model/c-m-s/cms-page-part"
import type { NewsCategoryGroup } from "@/model/c-m-s/news-category-group"
import type { NewsPost } from "@/model/c-m-s/news-post"
import { useHttpGet } from "@/vf"
import NewsCardHorizontal from "../../News/NewsCardHorizontal.vue"

const props = defineProps<{
    element: CmsPagePart & { config: { count: number } }
}>()

const { data } = await useHttpGet<{ news: NewsPost[]; categories: NewsCategoryGroup[] }>(
    "/cms/news/?limit=" +
        (props.element.config?.count ?? 6) +
        "&" +
        props.element.config?.newsCategories?.map(category => "categories[]=" + encodeURIComponent(category)).join("&"),
    true,
)
</script>

<template>
    <div v-for="post of data.news">
        <NewsCardHorizontal :post="post" />
    </div>
</template>
