<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    iban?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    bic?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    accountOwner?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_ProductGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: ProductGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_IssueGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: IssueGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "AccountInvitationStaffMember" | "AccountInvitationOrganizationMember" | "AccountInvitationOrganizationManager" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default
     */
    issueGroup?: _VTI_TYPE_IssueGroup
    /**
     * Groups: default
     */
    productGroup?: _VTI_TYPE_ProductGroup
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: list
     */
    starred?: boolean
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import { ChampionshipRegistrationTeam } from '@/model/championship/championship-registration-team'
import { ChampionshipRegistrationWaitingListTask } from '@/model/championship/championship-registration-waiting-list-task'
import { useHttpClient, useHttpGet } from '@/vf'
import { computed, ref, toRefs } from 'vue'
import ChampionshipRegistrationApprovalStateList from './ChampionshipRegistrationApprovalStateList.vue'
import ChampionshipRegistrationTeamList from './ChampionshipRegistrationTeamList.vue'
import { ChampionshipRegistrationSelectTeamData } from './championship-registration'
const props = defineProps<TasksProps<ChampionshipRegistrationWaitingListTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const { data, refresh } = await useHttpGet<ChampionshipRegistrationSelectTeamData>(
    `/championship/registration/${task.value.organizationId}/waiting-list/${task.value.id}`,
)
const http = useHttpClient()
const waitingTeams = computed(() =>
    data.value.teams.filter(
        team => team.approvalState === "waiting_list" || team.approvalState === "waiting_list_approved",
    ),
)
/*─────────────────────────────────────┐
│  submit                              │
└─────────────────────────────────────*/
const submitting = ref(false)
async function submit(allTeamsDecided: boolean, approvalStates: Record<string, string>) {
    if (!allTeamsDecided) {
        return
    }
    submitting.value = true
    try {
        await http.post(`/championship/registration/${data.value.registrationId}/waiting-list`, {
            teams: approvalStates,
        })
    } finally {
        await refresh()
        emit("updated")
        submitting.value = false
    }
}
/*─────────────────────────────────────┐
│  accept waiting list approval        │
└─────────────────────────────────────*/
async function acceptWaitingListApproval(team: ChampionshipRegistrationTeam) {
    submitting.value = true
    try {
        await http.post(
            `/championship/registration/${data.value.registrationId}/waiting-list/accept-waiting-list-approval`,
            { teamIds: [team.id] },
        )
        await refresh()
        emit("updated")
    } finally {
        submitting.value = false
    }
}
</script>
<template>
    <div class="p-5">
        <div v-if="viewMode === 'customer'">
            <div class="alert alert-info mb-5 mt-3">
                {{ $t("@tasks:tasks.championship_registration.approve.approval_pending") }}
            </div>

            <ChampionshipRegistrationTeamList :teams="waitingTeams" :view-mode="props.viewMode">
                <template #secondaryRow="{ team, colspan }">
                    <tr v-if="team.approvalState === 'waiting_list_approved'">
                        <td :colspan="colspan" class="bg-white px-5 py-4">
                            <div class="mb-4">
                                {{
                                    $t(
                                        "@tasks:tasks.championship_registration.waiting_list.office_approved_waiting_for_organization.prompt",
                                    )
                                }}
                            </div>

                            <button
                                type="button"
                                @click="acceptWaitingListApproval(team)"
                                class="btn btn-primary"
                                :disabled="submitting"
                            >
                                {{
                                    $t(
                                        "@tasks:tasks.championship_registration.waiting_list.office_approved_waiting_for_organization.button",
                                    )
                                }}
                            </button>
                        </td>
                    </tr>
                </template>
            </ChampionshipRegistrationTeamList>
        </div>
        <div v-else>
            <ChampionshipRegistrationApprovalStateList
                :data="data"
                :teams="waitingTeams"
                @updated="refresh()"
                approve-state="waiting_list_approved"
                :view-mode="props.viewMode"
            >
                <template #bottom="{ allTeamsDecided, approvalStates }">
                    <div class="d-flex justify-content-end">
                        <button
                            class="btn btn-primary mt-5"
                            :disabled="!allTeamsDecided || submitting"
                            @click="submit(allTeamsDecided, approvalStates)"
                        >
                            {{ $t("crud:button.save") }}
                        </button>
                    </div>
                </template>
            </ChampionshipRegistrationApprovalStateList>
        </div>
        <!-- <ChampionshipRegistrationTeamList -->
        <!--     :teams="waitingTeams" -->
        <!--     :show-price="true" -->
        <!--     :show-edit="false" -->
        <!--     :show-delete="false" -->
        <!--     :data="data" -->
        <!--     @updated="refresh()" -->
        <!-- /> -->
    </div>
</template>
