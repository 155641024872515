<script setup lang="ts">
import VfFormCollectionRepeat from "@/vf/components/crud/form/VfFormCollectionRepeat.vue"
import VfFormGroup from "@/vf/components/crud/form/VfFormGroup.vue"
import { findDataForName, findSchemaForName } from "@/vf/utils/SymfonyForm"
import { inject, nextTick, provide, ref, toRef, watch, type Ref } from "vue"

const props = defineProps<{
    name: string
    disabled?: boolean
    overrideDefaultSlot?: boolean
}>()

watch(
    () => props.disabled,
    () => {
        if (props.disabled) {
            console.warn("VfFormCollection does not support disabling yet")
        }
    },
)

const path = inject<Ref<string | undefined>>("vf-form-group", ref(undefined))
const name = path.value ? path.value + "." + props.name : props.name
let data = findDataForName(name, "VfFormCollection")

if (!Array.isArray(data.value[props.name])) {
    data.value[props.name] = []
}
const schema = findSchemaForName(props.name, "VfFormCollection", path.value).children
//console.log("VfFormCollection", props, data, schema)
provide("vf-form-collection", {
    data,
    schema,
    name: toRef(props, "name"),

    removeRow: async (index: number) => {
        const old = data.value[props.name]
        show.value = false
        await nextTick()
        data.value[props.name] = old.filter((_: any, i: number) => i !== index)
        show.value = true
    },
})

function addRow() {
    data.value[props.name].push({})
}

const show = ref(true)
</script>

<template>
    <template v-if="show">
        <VfFormGroup :name="props.name">
            <slot
                :name="props.overrideDefaultSlot ? 'overriddenDefault' : 'default'"
                v-bind="{ addRow, data: data[props.name] }"
            >
                <VfFormCollectionRepeat></VfFormCollectionRepeat>
                <button type="button" class="btn btn-secondary" @click="addRow()">{{ $t("crud:button.add") }}</button>
            </slot>
        </VfFormGroup>
    </template>
</template>
