<script setup lang="ts">
import { $fileTypeIcon } from "@/vf"
import type { Ref } from "vue"
import { ref } from "vue"

const props = defineProps<{
    modelValue?: File[]
}>()
const emit = defineEmits<{
    (e: "update:modelValue", value: File[]): void
}>()
const fileElement: Ref<HTMLInputElement | null> = ref(null)

function addAttachment() {
    if (fileElement.value) {
        fileElement.value.click()
    }
}

function removeAttachment(attachment: File) {
    if (props.modelValue) {
        emit(
            "update:modelValue",
            props.modelValue.filter(it => it !== attachment),
        )
    }
}

function fileSelected() {
    if (props.modelValue && fileElement.value) {
        if (!fileElement.value.files || !fileElement.value.files.length) {
            // no files selected
            return
        }
        const files = [...props.modelValue, ...Array.from(fileElement.value.files)]
        emit("update:modelValue", files)
    }
}
</script>

<template>
    <div class="bg-light p-4 border-top border-primary">
        <div class="row">
            <div class="col-md-6">
                <button
                    type="button"
                    class="btn btn-shape-rounded btn-shape-rounded-white btn-primary"
                    @click="addAttachment()"
                >
                    <i class="fa fa-fw fa-plus"></i>
                    <span>{{ $t("@communication:attachments.add_attachment") }}</span>
                </button>
            </div>
            <div class="col-md-6">
                <div v-for="file in props.modelValue" :key="file.name">
                    <div class="d-flex align-items-center">
                        <i :class="'fa-fw fs-1-5 ' + $fileTypeIcon(file.type)"></i>

                        <div class="flex-grow-1 py-2 px-2">
                            {{ file.name }}
                        </div>
                        <div>
                            <button
                                type="button"
                                class="btn btn-link p-2 m-0"
                                :title="$t('@communication:attachments.remove_attachment')"
                                @click="removeAttachment(file)"
                            >
                                <i class="fa fa-fw fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <input type="file" ref="fileElement" class="d-none" multiple @change="fileSelected()" />
    </div>
</template>
