<script setup lang="ts">
import type {
    Entity,
    ListFieldConfiguration,
    ListState,
    ModuleConfiguration,
    NormalizedListFieldGroupConfiguration,
} from "@/vf"
import { readField, renderField, renderVNode, useInlineTable, useModuleAction } from "@/vf"
import VfListButtons from "@/vf/components/crud/list/VfListButtons.vue"
import type { Component, VNode } from "vue"
import { computed, h, ref, toRefs, watch } from "vue"

const props = defineProps<{
    config: ModuleConfiguration
    listState: ListState
    item: Entity
    moduleAction: ReturnType<typeof useModuleAction>
    filteredFieldGroups: NormalizedListFieldGroupConfiguration[]
    class?: string
}>()

const { item, config, listState, moduleAction } = toRefs(props)
const inlineTable = useInlineTable(false)

function computeTdClass(
    field: ListFieldConfiguration,
    group: NormalizedListFieldGroupConfiguration,
    indexInGroup: number,
    indexInTable: number,
    indexInColumn: number,
    numberOfRowsInThisColumn: number,
) {
    let classes = []

    if (indexInTable === 0) {
        classes.push("vf-first-in-table")
    }

    if (indexInGroup === 0) {
        classes.push("vf-first-in-group")
    }

    if (indexInGroup === group.fields.length - 1) {
        classes.push("vf-last-in-group")
    }

    if (indexInColumn + 1 === numberOfRowsInThisColumn) {
        classes.push("vf-last-in-column")
    }

    if (field && field.tdClass) {
        classes.push(field.tdClass(props.item))
    }

    if (inlineTable.rowIsOpen(item.value.id) && config.value.list.showOpenRowAsTab) {
        // find the last clicked component of our row (there may be multiple of the row, but they are unloading)
        if (inlineTable.loadedComponent.value?.highlightKey === group.highlightKey) {
            classes.push("active-cell")
        }
    }

    return classes
}

// highlight keys of field groups whose multi rows should be expanded
const expandedMultiRows = ref<string[]>([])
type NormalizedCell = {
    rowspan: number
    colspan: number
    class: string[]
    onClick: () => any
    vnode: Component
}
type NormalizedRow = NormalizedCell[]

const rows = computed(() => {
    const columns: {
        rowsInColumn: VNode[]
        field: ListFieldConfiguration
        fieldGroup: NormalizedListFieldGroupConfiguration
        fieldIndex: number
        // start = cell where the expand button starts; span = don't output td; null = regular cell
        expandMultiRowButton?: "start" | "span"
        expandMultiRowColspan?: number
    }[] = []

    // names of field groups whose multi rows are collapsed (and thus a "show more" button must be rendered)
    const collapsedMultiRows = []

    // flatten field groups to columns
    for (const fieldGroup of props.filteredFieldGroups) {
        let indexInGroup = 0
        for (const field of fieldGroup.fields) {
            let rowsInThisColumn

            switch (field.type) {
                case "vnode":
                    rowsInThisColumn = [readField(item.value, field)]
                    break

                case "multi-row":
                    rowsInThisColumn = readField(item.value, field)

                    // if not expanded and maximum number of rows to render is exceeded, only render the first n rows
                    if (
                        rowsInThisColumn?.length > fieldGroup.collapseMultiRowsAfter &&
                        !expandedMultiRows.value.includes(fieldGroup.highlightKey)
                    ) {
                        rowsInThisColumn = rowsInThisColumn.slice(0, fieldGroup.collapseMultiRowsAfter)
                        collapsedMultiRows.push(fieldGroup.name)
                    }

                    break

                case "html":
                    rowsInThisColumn = [h("div", { innerHTML: readField(item.value, field) })]
                    break

                default:
                    rowsInThisColumn = [renderField(item.value, field)]
            }

            columns.push({
                rowsInColumn: rowsInThisColumn,
                field,
                fieldGroup,
                fieldIndex: indexInGroup++,
            })
        }
    }

    // add expand multi row buttons
    for (const fieldGroup of props.filteredFieldGroups) {
        if (!collapsedMultiRows.includes(fieldGroup.name)) {
            continue
        }

        for (const [columnIndex, column] of columns.filter(col => col.fieldGroup === fieldGroup).entries()) {
            if (columnIndex === 0) {
                column.rowsInColumn?.push(
                    h(
                        "button",
                        {
                            class: "btn btn-primary btn-block btn-shape-square text-white py-0",
                            // we currently have no project that needs this
                            // onClick: ($event: MouseEvent) => {
                            //     $event.stopImmediatePropagation()
                            //     expandedMultiRows.value.push(fieldGroup.name)
                            // },
                        },
                        h("i", { class: "fa fa-ellipsis-h" }),
                    ),
                )
                column.expandMultiRowButton = "start"
                column.expandMultiRowColspan = fieldGroup.fields.length
            } else {
                column.expandMultiRowButton = "span"
            }
        }
    }

    const numberOfTrsToRender = Math.max(...columns.map(column => column.rowsInColumn?.length))
    const rows: NormalizedRow[] = []

    for (let rowIndex = 0; rowIndex < numberOfTrsToRender; ++rowIndex) {
        const cellsInRow: NormalizedCell[] = []

        for (const [columnIndex, column] of columns.entries()) {
            let rowsInThisColumn = column.rowsInColumn

            if (rowIndex < rowsInThisColumn.length || rowIndex == 0) {
                let rowspan = rowsInThisColumn.length - 1 <= rowIndex ? Math.max(numberOfTrsToRender - rowIndex, 1) : 1
                let colspan = 1
                let extraClasses = []

                // for last row: add the expand buttons row- and colspan
                if (rowsInThisColumn.length === rowIndex + 1) {
                    switch (column.expandMultiRowButton) {
                        case "span":
                            rowspan = Math.max(1, rowspan - 1)
                            break

                        case "start":
                            colspan = column.expandMultiRowColspan!
                            extraClasses.push("p-0")
                            break
                    }
                }

                let numberOfRowsInThisColumn = Math.max(rowsInThisColumn.length, 1) // 0 multi row entries = 1 empty cell

                if (column.expandMultiRowButton === "span") {
                    // there is an extra displayed row in this column, because the first column has a colspan
                    numberOfRowsInThisColumn += 1
                }

                cellsInRow.push({
                    rowspan,
                    colspan,
                    class: [
                        ...computeTdClass(
                            column.field,
                            column.fieldGroup,
                            column.fieldIndex,
                            columnIndex,
                            rowIndex,
                            numberOfRowsInThisColumn,
                        ),
                        ...extraClasses,
                    ],
                    onClick: () => {
                        return listState.value.columnClicked(item.value, column.field, column.fieldGroup, rowIndex)
                    },

                    vnode: renderVNode(rowsInThisColumn[rowIndex]),
                })
            }
        }

        rows.push(cellsInRow)
    }

    return rows
})

const classes = ref<{ [key: string]: true }>({})
watch(
    () => props.class,
    () => {
        classes.value = {}
        for (const c of props.class?.split(" ") ?? []) {
            classes.value[c] = true
        }
    },
    {
        immediate: true,
    },
)
</script>

<template>
    <tr
        v-for="(row, rowIndex) in rows"
        :key="item.id + '-' + rowIndex"
        :class="{
            'active-row': inlineTable.rowIsOpen(item.id),
            'border-bottom-0': inlineTable.rowIsOpen(item.id) && config.list.showOpenRowAsTab,
            'row-clickable': config.list.rowClickable(item),
            ...(config.list.trClass?.(item) ?? {}),
            ...classes,
        }"
    >
        <td
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            :rowspan="cell.rowspan"
            :class="cell.class"
            :colspan="cell.colspan"
            @click="cell.onClick"
        >
            <component :is="cell.vnode"></component>
        </td>

        <td
            v-if="rowIndex === 0 && (config.list.buttonColumn() || $slots.listButtons)"
            class="text-right border-left-0"
        >
            <slot name="beforeListButtons" v-bind="{ item, moduleAction, listState }"></slot>
            <slot name="listButtons" v-bind="{ item, inlineTable, listState }">
                <VfListButtons :config="config" :list-state="listState" :item="item"></VfListButtons>
            </slot>
            <slot name="afterListButtons" v-bind="{ item, moduleAction, listState }"></slot>
        </td>
    </tr>
</template>
