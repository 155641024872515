<script setup lang="ts">
import { type InlineConfirm } from "@/composables/createInlineConfirm"
import { sleep } from "@/vf"
import { useElementBounding, useMousePressed, usePointer } from "@vueuse/core"
import gsap from "gsap"
import { computed, ref, toRefs, watch, type Ref } from "vue"

const props = withDefaults(
    defineProps<{
        controller: InlineConfirm
        closeTimeout?: number
        danger?: boolean
        spinner?: boolean
    }>(),
    {
        closeTimeout: 3000,
        danger: false,
        spinner: false,
    },
)

const { controller, closeTimeout } = toRefs(props)

type NippelPositionState = "left" | "neutral" | "right"

const container: Ref<HTMLDivElement | null> = ref(null)
const nippleMiddlePosition = 17.5

/*─────────────────────────────────────┐
│   animate opening and closing        │
└─────────────────────────────────────*/
async function animate(element: HTMLElement, open: boolean) {
    await gsap.to(element, {
        duration: 0.5,
        height: open ? "auto" : 0,
    })
}

controller.value.onOpenOrClose((open: boolean) => {
    if (open) {
        resetState()
    }

    if (container.value) {
        return animate(container.value, open)
    }
})

function resetState() {
    latestPosition.value = nippleMiddlePosition
    state.value = "neutral"

    if (spinnerElement?.value) {
        spinnerElement.value.style.height = "0"
    }

    if (confirmElement.value && submittedElement.value) {
        confirmElement.value.style.height = "auto"
        submittedElement.value.style.height = "0"
    }

    moveNippelToState("neutral")
}

/*─────────────────────────────────────┐
│   confirmation state elements        │
└─────────────────────────────────────*/
const confirmElement: Ref<HTMLDivElement | null> = ref(null)
const nippelContainer: Ref<HTMLDivElement | null> = ref(null)
const nippel: Ref<HTMLDivElement | null> = ref(null)
const state: Ref<NippelPositionState> = ref("neutral")

const latestPosition = ref(nippleMiddlePosition)

const nippelRect = useElementBounding(nippel)
const nippelContainerRect = useElementBounding(nippelContainer)

function setState(newState: NippelPositionState) {
    state.value = newState
    moveNippelToState(newState)
    handleFinalState()
}

function updateRects() {
    nippelRect.update()
    nippelContainerRect.update()
}

/**
 * Move nippel to given discrete state
 */
function moveNippelToState(state: NippelPositionState) {
    updateRects()
    switch (state) {
        case "left":
            moveNippel(0)
            break
        case "neutral":
            moveNippel(nippelContainerRect.width.value / 2 - nippelRect.width.value / 2)
            break
        case "right":
            moveNippel(nippelContainerRect.width.value - nippelRect.width.value)
            break
    }
}

/**
 * Move nippel to given x coordinate
 */
function moveNippel(x: number) {
    latestPosition.value = x
    latestPosition.value = Math.min(nippelContainerRect.width.value - nippelRect.width.value, latestPosition.value)
    latestPosition.value = Math.max(0, latestPosition.value)

    if (nippel.value) {
        nippel.value.style.transform = "translateX(" + latestPosition.value + "px)"
    }
}

/**
 * If nippel is in final state, perform action based on state
 */
async function handleFinalState() {
    switch (state.value) {
        case "left":
            controller.value.close()
            break
        case "right":
            await showSuccessAndResolve()
            break
    }
}

/*─────────────────────────────────────┐
│   nippel drag behaviour              │
└─────────────────────────────────────*/
const dragStartedAt: Ref<number | null> = ref(null)

const { pressed: holdingNippel } = useMousePressed({ target: nippel })
// cursor position relative to the viewport
const { x: cursorPagePositionX } = usePointer()
// cursor position relative to the nippel container
const cursorPositionX = computed(() => cursorPagePositionX.value - nippelContainerRect.left.value)

function getSideFromPosition(): NippelPositionState {
    const third = nippelContainerRect.width.value / 3
    const middlePosition = latestPosition.value + nippelRect.width.value / 2

    if (middlePosition < third) {
        return "left"
    } else if (middlePosition < 2 * third) {
        return "neutral"
    } else {
        return "right"
    }
}

watch(holdingNippel, isPressed => {
    if (isPressed) {
        updateRects()
        dragStartedAt.value = cursorPositionX.value - latestPosition.value
    } else {
        state.value = getSideFromPosition()
        moveNippelToState(state.value)
        handleFinalState()
    }
})

watch(cursorPositionX, x => {
    if (holdingNippel.value && dragStartedAt.value) {
        moveNippel(x - dragStartedAt.value)
    }
})

/*─────────────────────────────────────┐
│   spinner element                    │
└─────────────────────────────────────*/
const spinnerElement: Ref<HTMLDivElement | null> = ref(null)

/*─────────────────────────────────────┐
│   submitted state elements           │
└─────────────────────────────────────*/
const submittedElement: Ref<HTMLDivElement | null> = ref(null)

/*─────────────────────────────────────┐
│   state change confirm --> success   │
└─────────────────────────────────────*/
async function showSuccessAndResolve() {
    if (confirmElement.value && submittedElement.value) {
        let saveSuccess: boolean
        if (props.spinner && spinnerElement.value) {
            await animate(confirmElement.value, false)
            await animate(spinnerElement.value, true)
            saveSuccess = (await controller.value.action()) !== false
            await animate(spinnerElement.value, false)
        } else {
            saveSuccess = (await controller.value.action()) !== false
            await animate(confirmElement.value, false)
        }

        if (!saveSuccess) {
            await controller.value.close()
            return
        }

        await animate(submittedElement.value, true)
        await sleep(closeTimeout.value)
        await controller.value.close()

        controller.value.afterAction()
    }
}
</script>

<template>
    <div ref="container" style="height: 0; overflow: hidden">
        <div
            class="inline-dialog inline-confirm"
            :class="'inline-confirm-state-' + state + ' ' + (props.danger ? 'inline-dialog-danger' : '')"
        >
            <div ref="confirmElement" style="overflow: hidden">
                <div class="inline-confirm-confirmation">
                    <slot name="confirmation"></slot>
                </div>

                <div class="inline-confirm-buttons">
                    <button type="button" class="inline-confirm-left" @click="setState('left')">
                        <slot name="left">{{ $t("@tasks:tasks.confirm.no") }}</slot>
                    </button>
                    <div class="inline-confirm-toggle" ref="nippelContainer">
                        <div class="inline-confirm-toggle-nippel" ref="nippel"></div>
                    </div>
                    <button type="button" class="inline-confirm-right" @click="setState('right')">
                        <slot name="right">{{ $t("@tasks:tasks.confirm.yes") }}</slot>
                    </button>
                </div>
            </div>

            <div ref="spinnerElement" class="text-center" style="height: 0; overflow: hidden" v-if="props.spinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">{{ $t("@tasks:tasks.confirm.loading") }}...</span>
                </div>
                <div>
                    <slot name="spinnerText">
                        {{ $t("@tasks:tasks.confirm.spinnerText") }}
                    </slot>
                </div>
            </div>

            <div ref="submittedElement" class="text-center" style="height: 0; overflow: hidden">
                <div class="my-4">
                    <i class="fa fa-check text-primary fa-2x"></i>
                </div>
                <slot name="success"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/styles/variables";

.inline-confirm {
    .inline-confirm-confirmation {
        text-align: center;
        margin-bottom: 2.5rem;
    }

    .inline-confirm-buttons {
        display: grid;
        grid-template-columns: 1fr 8rem 1rem 2rem 1rem 8rem 1fr;
        height: 2.5rem;

        .inline-confirm-left,
        .inline-confirm-right {
            color: #fff;
            border: none;
            grid-row: 1;
            padding: 0.25rem 0.5rem;
            font-family: "Roboto Slab", sans-serif;
            position: relative;

            &:focus {
                outline: none;
            }

            &,
            &::after,
            &::before {
                transition: background-color 300ms ease-in-out;
            }
        }

        .inline-confirm-left::before,
        .inline-confirm-right::after {
            display: inline-block;
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            width: 33px;
        }

        .inline-confirm-left {
            grid-column: 2 / span 2;
            padding-right: calc(0.5rem + 33px);

            &,
            &::before {
                background-color: #aeafb2;
            }

            &::before {
                clip-path: polygon(60% -10%, 150% -10%, 150% 150%, 0 100%);
                left: -30px;
            }
        }

        .inline-confirm-right {
            grid-column: 5 / span 2;
            padding-left: calc(0.5rem + 33px);

            &,
            &::after {
                background-color: #aeafb2;
            }

            &::after {
                clip-path: polygon(0 -10%, 100% -10%, 10% 150%, 0 150%);
                right: -30px;
            }
        }

        .inline-confirm-toggle {
            z-index: 1;
            grid-column: 3 / span 3;
            grid-row: 1;
            background-color: #fff;
            border-radius: 2.5rem;
            border: 1px solid #aeafb2;
            display: flex;

            .inline-confirm-toggle-nippel {
                align-self: center;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0 0 3px 0.25rem #585a60, 0 0 0 0.5rem #aeafb2;
                //transition:       transform 300ms ease-in-out;
                transform: translateX(17.5px);
            }
        }
    }

    &.inline-confirm-state-left .inline-confirm-buttons {
        .inline-confirm-toggle-nippel {
            transform: translateX(0rem);
        }

        .inline-confirm-left,
        .inline-confirm-left::before {
            background-color: $primary;
        }
    }

    &.inline-confirm-state-right .inline-confirm-buttons {
        .inline-confirm-toggle-nippel {
            transform: translateX(2rem);
        }

        .inline-confirm-right,
        .inline-confirm-right::after {
            background-color: $primary;
        }
    }
}
</style>
