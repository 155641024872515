<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    iban?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    bic?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    accountOwner?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_ProductGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: ProductGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_IssueGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: IssueGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "AccountInvitationStaffMember" | "AccountInvitationOrganizationMember" | "AccountInvitationOrganizationManager" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default
     */
    issueGroup?: _VTI_TYPE_IssueGroup
    /**
     * Groups: default
     */
    productGroup?: _VTI_TYPE_ProductGroup
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: list
     */
    starred?: boolean
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import ContactAddress from '@/components/ContactAddress.vue'
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { AcceptOrganizationRequestTask } from '@/model/app/accept-organization-request-task'
import { useHttpClient } from '@/vf'
import { toRefs } from 'vue'
const props = defineProps<TasksProps<AcceptOrganizationRequestTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const { task } = toRefs(props)
const http = useHttpClient()
type SimilarOrganization = { name: string; similarity: number; address: string }
let similarOrganizations: SimilarOrganization[] = []
if (props.viewMode === "admin" && props.issue.owner) {
    similarOrganizations = (
        await http.get<SimilarOrganization[]>(
            `/organization/${props.issue.owner.id}/similar-organizations/${props.task.id}`,
        )
    ).data
}
const inlineConfirm = createInlineConfirm({
    async action() {
        await http.post(`/organization/${task.value.organizationId}/approve/${props.task.id}`, { approved: true })
    },
    afterAction() {
        emit("updated")
    },
})
const inlineDecline = createInlineConfirm({
    async action() {
        await http.post(`/organization/${task.value.organizationId}/approve/${props.task.id}`, { approved: false })
    },
    afterAction() {
        emit("updated")
    },
})
</script>

<template>
    <div class="p-4">
        <h3 class="mb-4">{{ $t(task.name!, task.nameProps) }}</h3>

        <div class="row">
            <div class="col-md-6">
                <dl>
                    <dt>Name</dt>
                    <dd>
                        <h6 class="m-0">{{ task.organizationName }}</h6>
                    </dd>
                    <template v-if="task.address">
                        <dt>{{ $t("@tasks:tasks.accept_organization_request.registration_address") }}</dt>
                        <dd>
                            <ContactAddress :address="task.address"></ContactAddress>
                        </dd>
                    </template>
                </dl>
            </div>
            <div class="col-md-6">
                <dl>
                    <dt>Level</dt>
                    <dd>{{ $t("@app:organization.level." + task.organizationLevel) }}</dd>

                    <template v-if="task.organizationLevel !== 'main'">
                        <dt>{{ $t("@app:organization.level.main") }}</dt>
                        <dd>
                            <div>{{ task.mainOrganizationName }}</div>
                            <div class="small text-muted">{{ task.mainOrganizationNumber }}</div>
                        </dd>
                    </template>
                    <template v-if="props.viewMode === 'admin' && similarOrganizations.length > 0">
                        <dt>{{ $t("@tasks:tasks.accept_organization_request.possible_doubles") }}</dt>
                        <dd>
                            <table class="table table-transparent table-borderless">
                                <tr v-for="similarOrganization in similarOrganizations">
                                    <td>
                                        {{ similarOrganization.name }}
                                    </td>
                                    <td>
                                        {{ similarOrganization.address }}
                                    </td>
                                </tr>
                            </table>
                        </dd>
                    </template>
                </dl>
            </div>
        </div>

        <div v-if="props.task.state === 'closed'" class="my-4 text-center">
            {{ $t("@tasks:tasks.accept_organization_request.task_approved") }}
        </div>

        <div v-if="props.task.state === 'rejected'" class="my-4 text-center">
            {{ $t("@tasks:tasks.accept_organization_request.task_rejected") }}
        </div>

        <div v-if="viewMode === 'admin' && task.state === 'open'" class="mt-4">
            <VarsityInlineConfirmGroup>
                <VarsityInlineConfirmButton class="btn btn-success btn-shape-skewed" :controller="inlineConfirm">
                    {{ $t("@tasks:tasks.accept_organization_request.approve") }}
                </VarsityInlineConfirmButton>
                <VarsityInlineConfirmButton class="btn btn-danger btn-shape-skewed" :controller="inlineDecline">
                    {{ $t("@tasks:tasks.accept_organization_request.decline") }}
                </VarsityInlineConfirmButton>
                <VarsityInlineConfirm :controller="inlineConfirm">
                    <template #confirmation>
                        {{ $t("@tasks:tasks.accept_organization_request.approve_confirm") }}
                    </template>
                    <template #success>
                        {{ $t("@tasks:tasks.accept_organization_request.approve_successfull") }}
                    </template>
                </VarsityInlineConfirm>
                <VarsityInlineConfirm :controller="inlineDecline">
                    <template #confirmation>
                        {{ $t("@tasks:tasks.accept_organization_request.decline_confirm") }}
                    </template>
                    <template #success>
                        {{ $t("@tasks:tasks.accept_organization_request.decline_successfull") }}
                    </template>
                </VarsityInlineConfirm>
            </VarsityInlineConfirmGroup>
        </div>
    </div>
</template>
