<script setup lang="ts">
import { sleep } from "@/vf"
import type { ComponentPublicInstance, Ref } from "vue"
import { computed, reactive, ref } from "vue"
import FrontendFragment from "../FrontendFragment.vue"

const props = defineProps<{
    element: any
}>()

async function selectTab(tab: number) {
    activeTab.value = activeTab.value === tab ? null : tab

    if (activeTab.value !== null) {
        // wait for animation to finish and scroll into view
        await sleep(500)
        ;(tabs[tab] as Element).scrollIntoView({ behavior: "smooth" })
    }
}

const activeTab = ref<number | null>(props.element.config.accordionEnumFirstTabOpen ? 0 : null)
const tabs = reactive<Record<number, Element | ComponentPublicInstance | null>>({})

const sidebar = computed(() => props.element.config.showSidebar)
</script>

<template>
    <div class="accordion-enumeration" :class="{ row: sidebar }">
        <div class="col-lg-3 col-xl-2" v-if="sidebar">
            <div class="accordion-enumeration-menu">
                <div
                    class="accordion-enumeration-menu-item"
                    :class="{ active: activeTab === $index }"
                    @click="selectTab($index)"
                    v-for="(tab, $index) in element?.columns[0]"
                >
                    {{ tab.title }}
                </div>
            </div>
        </div>
        <div :class="{ 'col-lg-9 col-xl-10': sidebar }">
            <div
                class="accordion-enumeration-card"
                v-for="(tab, $index) in element?.columns[0]"
                :ref="el => (tabs[$index] = el)"
            >
                <div class="accordion-enumeration-card-top" @click="selectTab($index)">
                    <h3>{{ tab.title }}</h3>
                    <div class="accordion-enumeration-card-toggle" :class="{ active: activeTab === $index }">
                        <i class="fa fa-fw fa-plus"></i>
                    </div>
                </div>

                <div v-animate-show="activeTab === $index">
                    <div class="accordion-enumeration-card-content">
                        <FrontendFragment v-bind="{ elements: tab.columns[0] }"></FrontendFragment>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
