<script setup lang="ts">
import {
    VfListCompose,
    VfListRow,
    computeThClass,
    sortListByField,
    useInlineTable,
    useModuleAction,
    type ListFieldConfiguration,
    type ListState,
    type ModuleConfiguration,
    type NormalizedListFieldGroupConfiguration,
} from "@/vf"

defineProps<{
    config: ModuleConfiguration
    listState: ListState
    fields: ListFieldConfiguration[]
    filteredFieldGroups: NormalizedListFieldGroupConfiguration[]
}>()

const moduleAction = useModuleAction()
const inlineTable = useInlineTable(false)
</script>

<template>
    <table class="table vf-crud-list" :class="{ 'table-row-tabs': config.list.showOpenRowAsTab }">
        <thead>
            <VfListCompose
                :config="config"
                :inline-table="inlineTable"
                :colspan="fields.length + (config.list.buttonColumn() || $slots.listButtons ? 1 : 0)"
                row-id="new"
            ></VfListCompose>
            <slot name="additionalHeaderRow"></slot>
            <template v-if="config.list.showFieldGroupsHeadRow">
                <tr class="vf-field-group-header">
                    <template v-for="(fieldGroup, groupIndex) of filteredFieldGroups" :key="fieldGroup.name">
                        <th
                            :colspan="fieldGroup.fields.length"
                            class="vf-first-in-group vf-last-in-group"
                            :class="{ 'vf-first-in-table': groupIndex === 0 }"
                        >
                            {{ $t(fieldGroup.title) }}
                        </th>
                    </template>
                </tr>
            </template>
            <tr>
                <template v-for="(fieldGroup, groupIndex) of filteredFieldGroups">
                    <template v-for="(field, fieldIndex) of fieldGroup.fields" :key="field.name">
                        <th
                            :class="computeThClass(field, fieldGroup, fieldIndex, groupIndex, listState).value"
                            @click="sortListByField(listState, field)"
                        >
                            {{ $t(field.title) }}

                            <div class="float-right d-grid">
                                <Transition
                                    enter-active-class="transition"
                                    enter-from-class="scale-0"
                                    enter-to-class="scale-1"
                                    leave-active-class="transition"
                                    leave-from-class="scale-1"
                                    leave-to-class="scale-0"
                                >
                                    <i
                                        class="grid-one fa fa-sort-up"
                                        v-if="listState.orderField === field.name && listState.orderDirection === 'asc'"
                                    ></i>
                                    <i
                                        class="grid-one fa fa-sort-down"
                                        v-else-if="
                                            listState.orderField === field.name && listState.orderDirection === 'desc'
                                        "
                                    ></i>
                                </Transition>
                            </div>
                        </th>
                    </template>
                </template>
                <th v-if="config.list.buttonColumn() || $slots.listButtons" class="border-left-0 text-right">
                    {{ $t("crud:list.actions") }}
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-for="item in listState.list" :key="item.id">
                <VfListRow v-bind="{ config, listState, item, moduleAction, filteredFieldGroups }">
                    <!-- slot passthrough-->
                    <template v-for="name in Object.keys($slots)" v-slot:[name]="slotData">
                        <slot :name="name" v-bind="slotData" />
                    </template>
                </VfListRow>
                <VfListCompose
                    :config="config"
                    :inline-table="inlineTable"
                    :colspan="fields.length + (config.list.buttonColumn() || $slots.listButtons ? 1 : 0)"
                    :row-id="item.id"
                ></VfListCompose>
            </template>
        </tbody>
    </table>
</template>
