<script setup lang="ts">
import RoundedButton from "@/components/RoundedButton.vue"
import VbListCustomizeFields from "@/components/crud/VbListCustomizeFields.vue"
import VbListPagination from "@/components/crud/VbListPagination.vue"
import VbListSearch from "@/components/crud/VbListSearch.vue"
import VbListTable from "@/components/crud/VbListTable.vue"
import VbListToolbar from "@/components/crud/VbListToolbar.vue"
import { VfList, type ModuleConfiguration } from "@/vf"
import { ref, toRefs } from "vue"

const props = withDefaults(
    defineProps<{
        config: ModuleConfiguration
        disableSearch?: boolean
        toolbar?: boolean
        header?: boolean
        pagination?: boolean
    }>(),
    {
        disableSearch: false,
        toolbar: true,
        header: true,
        pagination: true,
    },
)
const { config } = toRefs(props)

const customizeFieldsOpen = ref(false)
const list = ref<typeof VfList>()

defineExpose({
    refresh: () => list.value?.refresh(),
})
</script>

<template>
    <VfList
        :await="true"
        v-bind="{ config }"
        v-slot="{ listState, fields, filteredFieldGroups, moduleAction }"
        ref="list"
    >
        <div class="card">
            <div class="card-header" v-if="header">
                <slot name="header">
                    <div class="page-header">
                        <h1>{{ $t(config.readableName.plural) }}</h1>
                        <h2></h2>
                    </div>
                </slot>
            </div>
            <div class="card-toolbar" v-if="toolbar">
                <slot name="toolbar" v-bind="{ filter: listState.filter, refresh: listState.refresh, moduleAction }">
                    <VbListToolbar></VbListToolbar>
                    <VbListSearch @search="listState.refresh()" v-if="!disableSearch"></VbListSearch>

                    <RoundedButton
                        icon="fa fa-cog"
                        white
                        @click="customizeFieldsOpen = !customizeFieldsOpen"
                        :active="customizeFieldsOpen"
                        v-if="config.list.fieldsCustomizable"
                    >
                        Anpassen
                    </RoundedButton>
                </slot>
                <slot name="afterToolbar" v-bind="{ listState }"></slot>
            </div>
            <div class="card-body p-0">
                <div v-animate-show="customizeFieldsOpen">
                    <VbListCustomizeFields />
                </div>
                <slot name="beforeTable" v-bind="{ listState }"></slot>
                <div class="table-responsive position-relative">
                    <VbListTable v-bind="{ config, listState, fields, filteredFieldGroups }">
                        <!-- slot passthrough-->
                        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
                            <slot :name="name" v-bind="slotData" />
                        </template>
                    </VbListTable>
                </div>
                <VbListPagination @page-changed="listState.page = $event" v-if="pagination"></VbListPagination>
            </div>
        </div>
    </VfList>
</template>
