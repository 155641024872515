<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    iban?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    bic?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    accountOwner?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_ProductGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: ProductGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_IssueGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: IssueGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "AccountInvitationStaffMember" | "AccountInvitationOrganizationMember" | "AccountInvitationOrganizationManager" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default
     */
    issueGroup?: _VTI_TYPE_IssueGroup
    /**
     * Groups: default
     */
    productGroup?: _VTI_TYPE_ProductGroup
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: list
     */
    starred?: boolean
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import Pdf from '@/components/Pdf.vue'
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import ConditionForm from '@/components/form/ConditionForm.vue'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { JobActivity } from '@/model/staff/job-activity'
import { LoginJobActivity } from '@/model/staff/login-job-activity'
import ResponseAttachments from '@/pages/Tasks/Issue/ResponseAttachments.vue'
import { appendFormDataToFormData } from '@/utils/formData'
import { useHttpClient } from '@/vf'
import { computed, reactive, ref } from 'vue'
const props = defineProps<TasksProps<LoginJobActivity>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const http = useHttpClient()
const model = ref({
    attachments: [],
})
const inlineConfirmAccept = createInlineConfirm({
    async action() {
        const formData = new FormData()
        for (const condition of jobActivity.legalConditions) {
            const formElement = document.getElementById(condition.id) as HTMLFormElement
            if (!formElement) {
                // condition has no form
                continue
            }
            let conditionFormData = new FormData(formElement)
            conditionFormData = appendFormDataToFormData(
                formData,
                conditionFormData,
                "conditions[" + condition.id + "]",
            )
        }
        if (model?.value.attachments?.length > 0) {
            for (const file of model.value.attachments) {
                formData.append("attachments[]", file)
            }
        }
        await http.post(`/staff-admin/login-activity/${props.task.id}/accept`, formData)
    },
    afterAction() {
        emit("updated")
    },
})
const inlineConfirmDecline = createInlineConfirm({
    async action() {
        await http.post(`/staff-admin/login-activity/${props.task.id}/decline`, {})
    },
    afterAction() {
        emit("updated")
    },
})
const signatureFields = reactive<Record<string, { isEmpty: boolean; signature?: string; city?: string }>>({})
const hasEmptySignatureFields = computed(() => Object.values(signatureFields).some(i => i.isEmpty))
const jobActivity = (props.task as { jobActivity: JobActivity }).jobActivity
for (const condition of jobActivity.legalConditions) {
    signatureFields[condition.id] = { isEmpty: true }
}
// even though this isn't used, it's required to make the form work, because some values get calculated based on this
// object...
const data = ref({})
</script>

<template>
    <div class="p-4">
        <div v-if="viewMode === 'customer' && props.task.state === 'open'" class="mt-4">
            <template v-for="condition in jobActivity.legalConditions" :key="condition.id">
                <pdf :file="`/legal/form/pdf/staff/${props.task.id}/${condition.id}`"></pdf>
                <ConditionForm
                    v-model="data"
                    :form-ids="[condition.id]"
                    :form-id="condition.id"
                    @has-empty-signature-fields="signatureFields[condition.id].isEmpty = $event"
                ></ConditionForm>
            </template>
            <div class="form-group d-block" v-if="jobActivity.documentUploadRequired">
                <div v-html="jobActivity.documentUploadIntroText"></div>
                <label class="d-block font-weight-bold">{{ $t("@cms:contactForm.attachments") }}:</label>
                <ResponseAttachments v-model="model.attachments"></ResponseAttachments>
            </div>
            <VarsityInlineConfirmGroup>
                <VarsityInlineConfirmButton
                    class="btn btn-success btn-shape-skewed"
                    :controller="inlineConfirmAccept"
                    :disabled="
                        hasEmptySignatureFields ||
                        (jobActivity.documentUploadRequired && model.attachments.length === 0)
                    "
                >
                    {{ $t("@tasks:tasks.account_invitation.accept", { name: jobActivity.name }) }}
                </VarsityInlineConfirmButton>
                <VarsityInlineConfirmButton class="btn btn-danger btn-shape-skewed" :controller="inlineConfirmDecline">
                    {{ $t("@tasks:tasks.account_invitation.decline", { name: jobActivity.name }) }}
                </VarsityInlineConfirmButton>

                <VarsityInlineConfirm :controller="inlineConfirmAccept" :spinner="true">
                    <template #confirmation>{{ $t("@tasks:tasks.login_job_activity.accept_confirm") }}</template>
                    <template #success>{{ $t("@tasks:tasks.login_job_activity.accept_successfull") }}</template>
                </VarsityInlineConfirm>

                <VarsityInlineConfirm :controller="inlineConfirmDecline">
                    <template #confirmation>{{ $t("@tasks:tasks.login_job_activity.decline_confirm") }}</template>
                    <template #success>{{ $t("@tasks:tasks.login_job_activity.decline_successfull") }}</template>
                </VarsityInlineConfirm>
            </VarsityInlineConfirmGroup>
        </div>
    </div>
</template>
