<script setup lang="ts">
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import ConditionRenderInline from "@/pages/Conditions/Render/ConditionRenderInline.vue"
import { useNavbarState } from "@/stores"
import { useApp } from "@/vf"
import VfForm from "@/vf/components/crud/form/VfForm.vue"
import type { Ref } from "vue"
import { ref } from "vue"
import { useRouter } from "vue-router"

const navbarState = useNavbarState()
const { apiLink } = useApp()

const model = ref({
    username: "",
    email: navbarState.invitationHint?.email ?? "",
    firstName: "",
    lastName: "",
    plainPassword: {
        first: "",
        second: "",
    },
})
const contactModel = ref()
const contactForm = ref<typeof VfForm>()

function captureFormModel(model) {
    contactModel.value = model.value
}

const formErrors: Ref<any> = ref({})

async function save() {
    if (step.value === 0) {
        const response = await http.post("/registration/check-username-and-email", {
            username: model.value.username,
            email: model.value.email,
        })
        usernameStatus.value = response.content.usernameStatus
        emailStatus.value = response.content.emailStatus
        if (usernameStatus.value === "available" && emailStatus.value === "available") {
            step.value = 1
        }
        return
    }
    try {
        let token = ""
        if (navbarState.invitationHint?.token) {
            token = "?token=" + navbarState.invitationHint.token
        }
        await http.post("/registration/register" + token, {
            // contact: {
            //     country: contactModel.value.country,
            //     addressLine: contactModel.value.addressLine,
            //     street: contactModel.value.street,
            //     streetNumber: contactModel.value.streetNumber,
            //     zip: contactModel.value.zip,
            //     city: contactModel.value.city,
            // },
            ...model.value,
        })
        mailSent.value = true

        if (token) {
            useRouter().push("/")
        }
    } catch (e) {
        formErrors.value = e.content
        const subformErrors: { [key: string]: string } = {}
        for (const [key, value] of Object.entries(formErrors.value)) {
            if (key.startsWith("contact.")) {
                subformErrors[key.replace("contact.", "")] = value as string
            }
        }
        contactForm.value.setErrors(subformErrors)
    }
}

const http = useLegacyHttpClient()

const invitation: Ref<any> = ref(null)
if (navbarState.invitationHint?.token) {
    invitation.value = (
        await http
            .createRequest("invitation/public/" + navbarState.invitationHint.token)
            .asGet()
            .withHeader("X-Account", "use-any")
            .send()
    ).content
}

const mailSent: Ref<any> = ref(false)
const acceptTerms: Ref<any> = ref(false)
const usernameStatus = ref<"unchecked" | "taken" | "available">("unchecked")
const emailStatus = ref<"unchecked" | "taken" | "available">("unchecked")
const step: Ref<any> = ref(0)
</script>

<template>
    <div>
        <div v-if="!mailSent">
            <form @submit.prevent="save()">
                <div class="card-body">
                    <h5 class="card-title" v-t="'@app:registration.title'"></h5>

                    <div class="my-3">
                        <VarsityContentProcessor :content="$t('@app:registration.info_text')" />
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="text-success" v-if="usernameStatus === 'available'">
                                <i class="fa fa-fw fa-check"></i>
                                <span v-t="'@app:registration.username_check.available'"></span>
                            </div>
                            <div class="text-danger" v-if="usernameStatus === 'taken'">
                                <i class="fa fa-fw fa-times"></i>
                                <span v-t="'@app:registration.username_check.unavailable'"></span>
                            </div>
                            <div class="form-group">
                                <label
                                    v-t="'@app:registration.username'"
                                    class="col-form-label required"
                                    for="registration_username"
                                ></label>
                                <div class="col-form-control">
                                    <input
                                        type="text"
                                        required
                                        class="form-control"
                                        v-model="model.username"
                                        id="registration_username"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                class="text-success"
                                v-if="emailStatus === 'available'"
                                id="registration_email_success"
                            >
                                <i class="fa fa-fw fa-check"></i>
                                <span v-t="'@app:registration.email_check.available'"></span>
                            </div>
                            <div
                                class="text-danger"
                                v-if="emailStatus === 'taken'"
                                id="registration_error_email_failed"
                            >
                                <i class="fa fa-fw fa-times"></i>
                                <span v-t="'@app:registration.email_check.unavailable'"></span>
                            </div>
                            <div class="form-group">
                                <label
                                    v-t="'@app:registration.email'"
                                    class="col-form-label required"
                                    for="registration_email"
                                ></label>
                                <div class="col-form-control">
                                    <input
                                        type="text"
                                        required
                                        class="form-control"
                                        v-model="model.email"
                                        @keypress.enter="step === 0 ? save() : void 0"
                                        id="registration_email"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-animate-show="step >= 1">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label
                                        v-t="'@app:registration.firstName'"
                                        class="col-form-label required"
                                        for="registration_first_name"
                                    ></label>
                                    <div class="col-form-control">
                                        <input
                                            type="text"
                                            required
                                            class="form-control"
                                            v-model="model.firstName"
                                            id="registration_first_name"
                                        />
                                    </div>
                                </div>
                                <div v-for="message of formErrors.firstName" class="alert alert-danger">
                                    {{ $t(message) }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label
                                        v-t="'@app:registration.lastName'"
                                        class="col-form-label required"
                                        for="registration_last_name"
                                    ></label>
                                    <div class="col-form-control">
                                        <input
                                            type="text"
                                            required
                                            class="form-control"
                                            v-model="model.lastName"
                                            id="registration_last_name"
                                        />
                                    </div>
                                </div>
                                <div v-for="message of formErrors.lastName" class="alert alert-danger">
                                    {{ $t(message) }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label
                                        v-t="'@app:registration.password.first'"
                                        class="col-form-label required"
                                        for="registration_password_first"
                                    ></label>
                                    <div class="col-form-control">
                                        <input
                                            type="password"
                                            required
                                            class="form-control"
                                            v-model="model.plainPassword.first"
                                            id="registration_password_first"
                                        />
                                    </div>
                                </div>
                                <div v-for="message of formErrors['plainPassword.first']" class="alert alert-danger">
                                    {{ $t(message) }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label
                                        v-t="'@app:registration.password.second'"
                                        class="col-form-label required"
                                        for="registration_password_second"
                                    ></label>
                                    <div class="col-form-control">
                                        <input
                                            type="password"
                                            required
                                            class="form-control"
                                            v-model="model.plainPassword.second"
                                            id="registration_password_second"
                                        />
                                    </div>
                                </div>
                                <div v-for="message of formErrors['plainPassword.second']" class="alert alert-danger">
                                    {{ $t(message) }}
                                </div>
                            </div>
                        </div>
                        <!-- <VfForm
                            ref="contactForm"
                            :schema-url="apiLink('registration/schema/contact')"
                            @model="captureFormModel($event)"
                            v-slot="{ formModel, schema, additionalData }"
                        >
                            <AccountContactFormFields :form-model="formModel"></AccountContactFormFields>
                        </VfForm> -->
                        <div class="mt-3 legal-text-box">
                            <ConditionRenderInline
                                configured-condition="General.User"
                                :text-factor="1.4"
                            ></ConditionRenderInline>
                        </div>

                        <div class="checkbox mt-3 mb-5 ml-1">
                            <a :href="apiLink('legal/show/General.User/pdf')" target="_blank" style="font-size: 1rem">
                                {{ $t("@app:registration.download_terms") }}
                            </a>
                            <br />

                            <label class="mt-3" id="registration_accept_terms">
                                <input type="checkbox" v-model="acceptTerms" />
                                <div class="form-toggler"></div>
                                <span>{{ $t("@app:registration.accept_terms") }}</span>
                            </label>
                            <div class="mt-4">
                                <VarsityContentProcessor
                                    :content="$t('@app:registration.privacy_info')"
                                    allow-all-tags
                                ></VarsityContentProcessor>
                            </div>
                        </div>
                    </div>

                    <div
                        class="d-flex justify-content-between align-items-lg-start mt-3 flex-column-reverse flex-lg-row"
                    >
                        <button
                            class="btn btn-secondary"
                            type="button"
                            v-t="'@app:registration.back'"
                            @click="navbarState.openCard('login')"
                        ></button>
                        <div class="py-2 py-lg-0"></div>
                        <button
                            class="btn btn-primary"
                            type="button"
                            v-t="'@app:registration.continue'"
                            :disabled="!model.username || !model.email"
                            v-if="step === 0"
                            @click="save()"
                            id="registration_btn_continue"
                        ></button>
                        <button
                            class="btn btn-primary"
                            type="submit"
                            v-t="invitation ? '@app:registration.register_and_accept' : '@app:registration.register'"
                            :disabled="!acceptTerms"
                            v-if="step === 1"
                            id="registration_btn_finish"
                        ></button>
                    </div>
                </div>
            </form>
        </div>
        <div v-else>
            <div class="card-body">
                <h5 class="card-title" v-t="'@app:registration.title'"></h5>
                <div class="alert alert-success mt-5" id="registration_successful_alert">
                    <span v-t="'@app:registration.success.title'"></span>
                    <br />
                    <br />
                    <span v-t="'@app:registration.success.body'"></span>
                </div>
                <div class="mt-4 text-right">
                    <button class="btn btn-primary" @click="navbarState.openCard(null)">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.legal-text-box {
    border: 1px solid #ccc;
    padding: 1rem;
    max-height: min(100vh, 32rem);
    overflow-y: scroll;
}
</style>
