import type { DeepMaybeRef } from "@vueuse/core"
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import axios from "axios"
import { defineStore, storeToRefs } from "pinia"
import { reactive, ref } from "vue"
import { vfConfig } from "../config/VfConfig"

// export type QueryParams = {
//     [key: string]: QueryParams | QueryParams[] | string | string[] | number | number[] | boolean | null | undefined
// }

// should be the same as above, but also allow typed objects with specific keys and no index signature
export type QueryParams<T extends Record<string, unknown> = {}> = T extends Record<string, infer V>
    ? V extends QueryParams | QueryParams[] | string | string[] | number | number[] | boolean | null | undefined
        ? T
        : never
    : never

export type HttpContent = FormData | DeepMaybeRef<QueryParams> | string | null

const runningHttpRequestsStore = defineStore("running-http-requests", () => {
    const runningHttpRequests = ref(0)

    return { runningHttpRequests }
})

function logResponse(method: string, url: string, response: AxiosResponse | undefined) {
    console.debug(
        `[${method}] "${url}" -> ${response?.status} ${
            response?.headers["x-debug-token-link"] ? response?.headers["x-debug-token-link"] : "(no profiler)"
        }`,
    )
}

function addRunningHttpRequestsInterceptors(client: AxiosInstance, method: string, url: string) {
    const { runningHttpRequests } = storeToRefs(runningHttpRequestsStore())
    client.interceptors.request.use(function (config) {
        runningHttpRequests.value++
        return config
    })
    client.interceptors.response.use(
        function (response) {
            runningHttpRequests.value--
            logResponse(method, url, response)
            return response
        },
        function (error) {
            logResponse(method, url, error.response)
            runningHttpRequests.value--
            return Promise.reject(error)
        },
    )
}

export function useHttpClient() {
    function request<T>(
        method: "post" | "put" | "get" | "delete",
        withInterceptors: boolean,
        url: string,
        data?: HttpContent,
        config?: AxiosRequestConfig,
    ) {
        const client = axios.create({
            baseURL: `//${determineApiAddress()}` + (import.meta.env.VITE_API_BASE ?? "/api"),
        })

        if (withInterceptors) {
            addRunningHttpRequestsInterceptors(client, method, url)
        }

        vfConfig.http.requestHandlers.forEach(handler => {
            handler({ method, url, data, config, client })
        })

        return client.request<T>({
            url,
            method,
            data: data !== null && typeof data === "object" ? reactive(data) : data,
            withCredentials: true,
            ...config,
        })
    }

    const { runningHttpRequests } = storeToRefs(runningHttpRequestsStore())
    return {
        request,
        get<T>(url: string, params?: QueryParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("get", true, url, undefined, { params, ...config })
        },
        getBg<T>(url: string, params?: QueryParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("get", false, url, undefined, { params, ...config })
        },
        delete<T>(url: string, params?: QueryParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("delete", true, url, undefined, { params, ...config })
        },
        deleteBg<T>(url: string, params?: QueryParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("delete", false, url, undefined, { params, ...config })
        },
        post<T>(url: string, content?: HttpContent, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("post", true, url, content, config)
        },
        postBg<T>(url: string, content?: HttpContent, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("post", false, url, content, config)
        },
        put<T>(url: string, content?: HttpContent, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("put", true, url, content, config)
        },
        putBg<T>(url: string, content?: HttpContent, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
            return request<T>("put", false, url, content, config)
        },
        runningHttpRequests,
    }
}

export function determineApiAddress(): string {
    if (
        location.hostname === "localhost" ||
        location.hostname.match(/^[\d.]+/) ||
        location.hostname.endsWith(".local")
    ) {
        return location.hostname + ":" + import.meta.env.VITE_API_PORT
    }

    return location.hostname
}
