<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    iban?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    bic?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    accountOwner?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_ProductGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: ProductGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_IssueGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: IssueGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "AccountInvitationStaffMember" | "AccountInvitationOrganizationMember" | "AccountInvitationOrganizationManager" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default
     */
    issueGroup?: _VTI_TYPE_IssueGroup
    /**
     * Groups: default
     */
    productGroup?: _VTI_TYPE_ProductGroup
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: list
     */
    starred?: boolean
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import Pdf from '@/components/Pdf.vue'
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import { useVarsityAppConfig } from '@/composables'
import { createInlineConfirm } from '@/composables/createInlineConfirm'
import { CompanyProfile } from '@/model/app/company-profile'
import { ApproveLoginJobActivityTask } from '@/model/staff/approve-login-job-activity-task'
import { useApp, useHttpClient } from '@/vf'
const props = defineProps<TasksProps<ApproveLoginJobActivityTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const http = useHttpClient()
const { apiLink } = useApp()
const inlineConfirmAccept = createInlineConfirm({
    async action() {
        await http.post(`/staff-admin/login-activity/approve/${props.task.id}/accept`)
    },
    afterAction() {
        emit("updated")
    },
})
const inlineConfirmDecline = createInlineConfirm({
    async action() {
        await http.post(`/staff-admin/login-activity/approve/${props.task.id}/decline`, {})
    },
    afterAction() {
        emit("updated")
    },
})
const { appConfig } = useVarsityAppConfig()
function hasSignature(companyProfile: CompanyProfile | undefined): boolean {
    if (!companyProfile) {
        return false
    }
    return !!appConfig.value.staffAccount?.hasSignatureFor?.map(c => c.id).includes(companyProfile.id)
}
</script>

<template>
    <div class="p-4">
        <div class="row">
            <div v-if="props.task.attachments.length > 0" class="col-md-6">
                <template v-for="attachment in props.task.attachments" :key="attachment.id">
                    <pdf
                        :file="`/tasks/task/${props.task.id}/attachment/${attachment.id}/download`"
                        class="mt-3"
                        v-if="attachment.mimeType == 'application/pdf'"
                    ></pdf>
                    <img
                        :src="apiLink(`/tasks/task/${props.task.id}/attachment/${attachment.id}/download`)"
                        v-if="attachment.mimeType.startsWith('image/')"
                        :alt="attachment.originalFilename"
                        class="img-fluid"
                    />
                </template>
            </div>
            <div v-if="props.task.state === 'open'" class="col-md-6 mt-4">
                <div v-if="viewMode === 'customer'" class="text-center my-5">
                    {{ $t("@tasks:tasks.approve_login_job_activity.waiting_approval_staff_member") }}
                </div>
                <div v-else-if="viewMode === 'admin'">
                    <div class="alert alert-danger" v-if="!hasSignature(task.companyProfile)">
                        {{ $t("@tasks:tasks.approve_login_job_activity.no_signature_found") }}
                    </div>
                    <VarsityInlineConfirmGroup v-if="hasSignature(task.companyProfile)">
                        <VarsityInlineConfirmButton
                            :controller="inlineConfirmAccept"
                            class="btn btn-success btn-shape-skewed"
                        >
                            {{
                                $t("@tasks:tasks.approve_login_job_activity.accept_button", {
                                    name: props.task.loginJobActivity?.jobActivity?.name,
                                })
                            }}
                        </VarsityInlineConfirmButton>
                        <VarsityInlineConfirmButton
                            :controller="inlineConfirmDecline"
                            class="btn btn-danger btn-shape-skewed"
                        >
                            {{
                                $t("@tasks:tasks.approve_login_job_activity.decline_button", {
                                    name: props.task.loginJobActivity?.jobActivity?.name,
                                })
                            }}
                        </VarsityInlineConfirmButton>

                        <VarsityInlineConfirm :controller="inlineConfirmAccept" :spinner="true">
                            <template #confirmation>
                                {{ $t("@tasks:tasks.approve_login_job_activity.accept_confirm") }}
                            </template>
                            <template #success>
                                {{ $t("@tasks:tasks.approve_login_job_activity.accept_successfull") }}
                            </template>
                        </VarsityInlineConfirm>

                        <VarsityInlineConfirm :controller="inlineConfirmDecline">
                            <template #confirmation>
                                {{ $t("@tasks:tasks.approve_login_job_activity.decline_confirm") }}
                            </template>
                            <template #success>
                                {{ $t("@tasks:tasks.approve_login_job_activity.decline_successfull") }}
                            </template>
                        </VarsityInlineConfirm>
                    </VarsityInlineConfirmGroup>
                </div>
            </div>

            <div v-else-if="props.task.state === 'closed'" class="text-center my-4">
                {{ $t("@tasks:tasks.approve_login_job_activity.task_approved") }}
            </div>

            <div v-else-if="props.task.state === 'rejected'" class="text-center my-4">
                {{ $t("@tasks:tasks.approve_login_job_activity.task_rejected") }}
            </div>
        </div>
    </div>
</template>
