<script lang="ts" setup>
import { VfFormRow } from "@/vf"
import { findDataForName, getVfFormInject } from "@/vf/utils/SymfonyForm"
import _ from "lodash"
import { ref } from "vue"

const props = defineProps<{
    name: string
    schema: any
    horizontal: boolean
}>()

//gets the path of a form element from the schema (e.g. "steps.step1.translations")
function getFormPath(name: string) {
    const form = getVfFormInject()
    let path: string[] = []

    //finds a key-value-pair ("name": name) in an object and adds breadcrumb to path array (e.g. ['[0]', 'children[0]', 'children[0]'])
    function findPathInObject(obj: Object | string[]): string | boolean {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
            return false
        } else if ("name" in obj && obj["name"] === name) {
            return true
        } else if (Array.isArray(obj)) {
            let parentKey = path.length ? path.pop() : ""

            for (let i = 0; i < obj.length; i++) {
                path.push(`${parentKey}[${i}]`)
                const result = findPathInObject(obj[i])
                if (result) {
                    return result
                }
                path.pop()
            }
        } else {
            for (const [key, value] of Object.entries(obj)) {
                path.push(key)
                const result = findPathInObject(value)
                if (result) {
                    return result
                }
                path.pop()
            }
        }

        return false
    }

    findPathInObject(form?.schema._rawValue)

    //gets name value for each breadcrumb in path array (e.g. ['steps', 'step1', 'translations'])
    let namedPaths = [name]

    if (path.length > 2) {
        for (let i = 0; i <= path.length; i++) {
            path.pop()
            namedPaths.unshift(_.get(form?.schema._rawValue, path.join(".") + ".name"))
        }
    }

    return namedPaths.join(".")
}

// schema that renders a single subform in the translations array. outer group is for the array name and inner for the index
function schema(lng: string) {
    for (let i = 0; i < data.value[props.name].length; i++) {
        data.value[props.name][i].id = undefined // TODO find the problem why convertEntityToFormModel does not remove this
        if (data.value[props.name][i].language === lng) {
            return {
                $formkit: "group",
                name: props.name,
                children: [
                    {
                        name: i.toString(),
                        $formkit: "group",
                        children: props.schema,
                    },
                ],
            }
        }
    }
    return null
}

function changeTab(lng: string) {
    activeTab.value = lng
}

function addEntry(lng: string) {
    data.value[props.name].push({
        language: lng,
    })
}

// function delEntry(lng: string) {
//     console.log('delEntry', lng)
//     for (let i = 0; i < data[props.name].length; i++) {
//         if (data[props.name][i].language === lng) {
//             data[props.name].splice(i, 1)
//             return
//         }
//     }
// }

const path = getFormPath(props.name)

let data = findDataForName(path, "TranslationsForm")

if (!data) {
    data = ref({})
}
if (!data.value[props.name]) {
    data.value[props.name] = []
}

let activeTab = ref("en")
let availableLanguages = ["en", "de"]
// if (props.horizontal) {
// init all languages
for (const lng of availableLanguages) {
    if (!schema(lng)) {
        addEntry(lng)
    }
}
// }
</script>

<template>
    <div class="translatable" v-if="!props.horizontal">
        <ul class="nav nav-tabs">
            <li class="nav-item" v-for="lng of availableLanguages" :key="lng">
                <a class="nav-link" :class="{ active: activeTab === lng }" @click="changeTab(lng)">{{ lng }}</a>
            </li>
        </ul>
        <div class="translatable-tab-body ${showShadow ? 'with-shadow-element' : ''}">
            <template v-for="lng of availableLanguages" :key="lng">
                <div v-show="activeTab === lng">
                    <template v-if="schema(lng)">
                        <VfFormRow :schema="schema(lng)" :data="data" :path="path"></VfFormRow>
                        <!--                        <button type="button" @click="delEntry(activeTab)" class="btn btn-danger" v-if="lng !== 'en'">-->
                        <!--                            <i class="fas fa-trash"></i>-->
                        <!--                            <span t="@translation:form.del"></span>-->
                        <!--                        </button>-->
                    </template>
                    <template v-else>
                        <div class="missing">
                            <button class="btn btn-light" @click="addEntry(activeTab)" type="button">
                                <i class="fas fa-plus-square fa-3x"></i>
                                <br />
                                <span t="@translation:form.add"></span>
                            </button>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-6" v-for="lng of availableLanguages" :key="lng">
            <div class="text-center">
                {{ lng }}
            </div>
            <VfFormRow :schema="schema(lng)" :data="data" :path="path"></VfFormRow>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.translatable {
    .nav-tabs .nav-item .tab-has-errors {
        background-color: red;
    }

    .translatable-tab-body {
        display: grid;
        align-items: stretch;

        > div {
            grid-column: 1;
            grid-row: 1;
        }

        .missing {
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
    }
}
</style>
