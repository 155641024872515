import { useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { ref } from "vue"

export interface GlobalState {
    todos?: { route: string; count: number }[]
}

export const useGlobalState = defineStore("global-state", () => {
    const globalState = ref<GlobalState>({})
    const http = useHttpClient()
    let readyResolve: ((value: void) => void) | null = null
    const globalStateReady = new Promise(resolve => {
        readyResolve = resolve
    })
    let timer: number | ReturnType<typeof setTimeout> | undefined = undefined

    async function refreshGlobalState() {
        globalState.value = (await http.getBg<GlobalState>("/global-state")).data
        if (readyResolve) {
            readyResolve()
            readyResolve = null
        }

        clearTimeout(timer) // clear a running timer if refreshGlobalState was called manually
        timer = setTimeout(refreshGlobalState, 60_000)
    }

    refreshGlobalState().then()

    return {
        globalState,
        globalStateReady,
        refreshGlobalState,
    }
})
