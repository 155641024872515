<script lang="ts" setup>
import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useAppConfig, type ModuleConfiguration } from "@/vf"
import { ref, watch } from "vue"
import VueMultiselect from "vue-multiselect"

const props = defineProps<{
    config: ModuleConfiguration
    customerView: boolean
}>()

const { appConfig } = useAppConfig()
const http = useLegacyHttpClient()
const selectedAccount = ref(null)

watch(selectedAccount, id => {
    if (id) {
        props.config.list.requestParams.value = {
            ...(props.config.list.requestParams.value ?? {}),
            accountId: selectedAccount.value.id,
        }
    } else {
        props.config.list.requestParams.value = {
            ...(props.config.list.requestParams.value ?? {}),
            accountId: null,
        }
    }
})

const accounts = ref([])
if (props.customerView) {
    appConfig.value.accesses.sort((a, b) => {
        const nameA = a.account?.name?.toUpperCase() // ignore upper and lowercase
        const nameB = b.account?.name?.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }

        // names must be equal
        return 0
    })
    for (const access of appConfig.value.accesses) {
        accounts.value.push(access.account)
    }
}

async function asyncFind(query) {
    if (!props.customerView) {
        accounts.value = (
            await http.get("/account-search", {
                name: query,
            })
        ).content
    }
}
</script>
<template>
    <div style="width: 300px" class="d-inline-block ml-4 mr-4">
        <VueMultiselect
            v-model="selectedAccount"
            :options="accounts"
            :multiple="false"
            :taggable="false"
            @search-change="asyncFind"
            placeholder="..."
            label="name"
            track-by="id"
        ></VueMultiselect>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
