export function $sectionName(n: string) {
    const map = {
        "building": "Building Skills",
        "tumbling": "Tumbling Skills",
        "overall": "Overall",
        "deductions": "Deductions",
        "building:stunt": "Building Skill \u2013 Stunt",
        "building:pyramid": "Building Skill \u2013 Pyramid",
        "building:tosses": "Building Skill \u2013 Tosses",
        "building:quantity": "Building Skill \u2013 Coed / Quantity",
        "building:performance": "Building Skill \u2013 Building Skill Performance",
        "tumbling:running": "Tumbling Skill \u2013 Running Tumbling",
        "tumbling:standing": "Tumbling Skill \u2013 Standing Tumbling",
        "tumbling:jumps": "Tumbling Skill \u2013 Jumps",
        "tumbling:quantity": "Tumbling Skill \u2013 Coed / Quantity",
        "tumbling:performance": "Tumbling Skill \u2013 Running Tumbling Performance",
        "overall:stunt": "Overall \u2013 Stunt Creativity",
        "overall:pyramid": "Overall \u2013 Pyramid Creativity",
        "overall:dance": "Overall \u2013 Dance",
        "overall:performance": "Overall \u2013 Performance",
        "overall:composition": "Overall \u2013 Routine Composition",
    }
    if (Object.keys(map).includes(n)) {
        return map[n]
    }
    return n
}

export function $sectionNameShort(n: string) {
    return (
        {
            building: "Building",
            tumbling: "Tumbling",
            overall: "Overall",
            deductions: "Deductions",
        }[n] || ""
    )
}

export function $panelLetter(n: number) {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".substring(n - 1, n)
}

export function $formatResult(n: number, precision: number = 1, basis: number = 100): string {
    if (isNaN(n)) {
        return "-"
    }
    if (!n && n !== 0) {
        return n?.toString()
    }

    return (n / basis).toFixed(precision)
}
