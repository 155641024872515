<script setup lang="ts">
import { $formatResult } from "@/utils"
import { sleep, useHttpGet } from "@/vf"
import { reactive, ref, type ComponentPublicInstance } from "vue"

const props = defineProps<{
    element: any
}>()

interface ScoreResult {
    withScore: [
        {
            name: string
            position: number
            teams: [
                {
                    place: number
                    name: string
                    club: string
                    country: string
                    score: number
                },
            ]
        },
    ]
    withoutScore: [
        {
            division: string
            team: string
            club: string
            country: string
        },
    ]
}

const { data: championshipResult } = await useHttpGet<ScoreResult>(
    "/scoring/score/public/" + props.element.config.selectedChampionship,
)

async function selectTab(tab: number) {
    activeTab.value = activeTab.value === tab ? null : tab

    if (activeTab.value !== null) {
        // wait for animation to finish and scroll into view
        await sleep(500)
        ;(tabs[tab] as Element).scrollIntoView({ behavior: "smooth" })
    }
}

const activeTab = ref<number | null>(0)
const tabs = reactive<Record<number, Element | ComponentPublicInstance | null>>({})
</script>

<template>
    <div class="accordion-enumeration" v-if="championshipResult">
        <div>
            <div class="accordion-enumeration-card" :ref="el => (tabs[0] = el)">
                <div class="accordion-enumeration-card-top" @click="selectTab(0)">
                    <h3>{{ $t("@cms:championshipResults.exhibitionOnly") }}</h3>
                    <div class="accordion-enumeration-card-toggle" :class="{ active: activeTab === 0 }">
                        <i class="fa fa-fw fa-plus"></i>
                    </div>
                </div>

                <div v-animate-show="activeTab === 0">
                    <div class="accordion-enumeration-card-content">
                        <div class="d-none d-md-block">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>{{ $t("@cms:championshipResults.division") }}</th>
                                        <th>{{ $t("@cms:championshipResults.team") }}</th>
                                        <th class="text-muted">{{ $t("@cms:championshipResults.club") }}</th>
                                    </tr>
                                    <tr v-for="team in championshipResult.withoutScore" :key="team.team">
                                        <td>{{ team.division }}</td>
                                        <td>{{ team.team }} ({{ team.country }})</td>
                                        <td class="text-muted">{{ team.club }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-block d-md-none">
                            <div
                                v-for="team in championshipResult.withoutScore"
                                :key="team.team"
                                class="py-3"
                                style="border-bottom: 1px solid #ccc"
                            >
                                <div>{{ team.team }} ({{ team.country }})</div>
                                <div class="text-muted small">{{ team.club }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="accordion-enumeration-card"
                v-for="(category, $index) in championshipResult.withScore"
                :ref="el => (tabs[$index + 1] = el)"
                :key="category.name"
            >
                <div class="accordion-enumeration-card-top" @click="selectTab($index + 1)">
                    <h3>{{ category.name }}</h3>
                    <div class="accordion-enumeration-card-toggle" :class="{ active: activeTab === $index + 1 }">
                        <i class="fa fa-fw fa-plus"></i>
                    </div>
                </div>

                <div v-animate-show="activeTab === $index + 1">
                    <div class="accordion-enumeration-card-content">
                        <div class="d-none d-md-block">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>{{ $t("@cms:championshipResults.place") }}</th>
                                        <th>{{ $t("@cms:championshipResults.results") }}</th>
                                        <th>{{ $t("@cms:championshipResults.team") }}</th>
                                        <th class="text-muted">{{ $t("@cms:championshipResults.club") }}</th>
                                    </tr>
                                    <tr v-for="team in category.teams" :key="team.name">
                                        <td>{{ team.place }}</td>
                                        <td>{{ $formatResult(team.score, 2) }}</td>
                                        <td>{{ team.name }} ({{ team.country }})</td>
                                        <td class="text-muted">{{ team.club }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-block d-md-none">
                            <div v-for="team in category.teams" :key="team.name" style="border-bottom: 1px solid #ccc">
                                <div class="pt-3 d-flex flex-row align-items-center mb-1">
                                    <div class="text-center" style="width: 80px; flex-shrink: 0">
                                        <div class="h3 m-0">{{ team.place }}.</div>
                                    </div>
                                    <div>{{ team.name }} ({{ team.country }})</div>
                                </div>
                                <div class="pb-3 d-flex flex-row align-items-end">
                                    <div class="small text-muted text-center" style="width: 80px; flex-shrink: 0">
                                        {{ $formatResult(team.score, 2) }}
                                    </div>
                                    <div class="text-muted small">{{ team.club }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
