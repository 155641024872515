<script setup lang="ts">
import VarsityContentProcessor from "@/components/VarsityContentProcessor.vue"
import CmsChampionshipResults from "@/pages/Cms/Frontend/Elements/CmsChampionshipResults.vue"
import CmsCondition from "@/pages/Cms/Frontend/Elements/CmsCondition.vue"
import CmsContactForm from "@/pages/Cms/Frontend/Elements/CmsContactForm.vue"
import CmsFaq from "@/pages/Cms/Frontend/Elements/CmsFaq.vue"
import CmsLoginForm from "@/pages/Cms/Frontend/Elements/CmsLoginForm.vue"
import { calculateStyle } from "../CmsPage/configuration"
import CmsAccordionEnumeration from "./Elements/CmsAccordionEnumeration.vue"
import CmsBigBanners from "./Elements/CmsBigBanners.vue"
import CmsBigPicture from "./Elements/CmsBigPicture.vue"
import CmsButtonLink from "./Elements/CmsButtonLink.vue"
import CmsEventOverview from "./Elements/CmsEventOverview.vue"
import CmsImage from "./Elements/CmsImage.vue"
import CmsImageGroup from "./Elements/CmsImageGroup.vue"
import CmsLinkList from "./Elements/CmsLinkList.vue"
import CmsOnlineForm from "./Elements/CmsOnlineForm.vue"
import CmsPdf from "./Elements/CmsPdf.vue"
import CmsPictureEnum from "./Elements/CmsPictureEnum.vue"
import CmsPictureGallery from "./Elements/CmsPictureGallery.vue"
import CmsPictureLinks from "./Elements/CmsPictureLinks.vue"
import CmsPictureText from "./Elements/CmsPictureText.vue"
import CmsTimeline from "./Elements/CmsTimeline.vue"
import CmsYouTube from "./Elements/CmsYouTube.vue"
import CmsNewsOverview from "./Elements/news/CmsNewsOverview.vue"
import CmsRelatedNews from "./Elements/news/CmsRelatedNews.vue"
import CmsSideNews from "./Elements/news/CmsSideNews.vue"
import FrontendFragment from "./FrontendFragment.vue"

const props = defineProps<{
    elements?: any[]
    page?: any
}>()

function getWrapperClass(element) {
    switch (element.type) {
        case "big-banners":
            return element.config?.fullWidth ? "" : "cms-container"
        case "image-group":
            return "h-100"
        default:
            return "cms-container"
    }
}

function prepareTextElement(html: string) {
    return html?.replaceAll("&lt;help&gt;", "<help>")?.replaceAll("&lt;/help&gt;", "</help>") ?? ""
}

/** Returns the css class for a sidebyside elements columns */
function getColumnClass(element, index: number) {
    const classes = [getColumnSpanClass(element, index)]

    if (element.config.sideBySideAlign[index] === "stretch") {
        classes.push("cms-col-stretch")
    }

    return classes
}

function getColumnSpanClass(element, index: number) {
    // sidebyside2 requires a special handling because we need backwards compatibility for elements
    // that have sideBySideLayout as a number instead of an array
    if (element.type === "sidebyside2") {
        if (index === 0) {
            return "col-md-" + (element.config.sideBySideLayout[0] || element.config.sideBySideLayout || 6)
        } else {
            return "col-md-" + (element.config.sideBySideLayout[1] || 12 - element.config.sideBySideLayout || 6)
        }
    }

    const columnsByType =
        {
            sidebyside3: 3,
            sidebyside4: 4,
        }[element.type] ?? 2

    const defaultSpan = 12 / columnsByType
    return "col-md-" + (element.config.sideBySideLayout[index] || defaultSpan)
}
</script>

<template>
    <!-- <div> -->
    <template v-for="element in props.elements">
        <div :class="getWrapperClass(element)" :id="element.id">
            <h1 v-if="element.type === 'heading' && element.config.level === 1" :style="calculateStyle(element)">
                {{ element.title }}
            </h1>
            <h2 v-if="element.type === 'heading' && element.config.level === 2" :style="calculateStyle(element)">
                {{ element.title }}
            </h2>
            <h3 v-if="element.type === 'heading' && element.config.level === 3" :style="calculateStyle(element)">
                {{ element.title }}
            </h3>
            <h4 v-if="element.type === 'heading' && element.config.level === 4" :style="calculateStyle(element)">
                {{ element.title }}
            </h4>

            <p v-if="element.type === 'text'" :style="calculateStyle(element)">
                <VarsityContentProcessor
                    :content="prepareTextElement(element.text)"
                    allow-all-tags
                ></VarsityContentProcessor>
            </p>

            <div v-if="element.type === 'image'" :style="calculateStyle(element)">
                <CmsImage v-bind="{ element: element }"></CmsImage>
            </div>

            <div v-if="element.type === 'vertical-space'" :style="calculateStyle(element)"></div>

            <div v-if="element.type === 'box'" class="cms-box" :style="calculateStyle(element)">
                <FrontendFragment v-bind="{ elements: element.columns[0], page: props.page }"></FrontendFragment>
            </div>

            <div
                class="ce-sidebyside2 ce-sidebyside row"
                v-if="element.type === 'sidebyside2'"
                :style="calculateStyle(element)"
                :class="{ 'cms-stretch': element.config.alignItems === 'stretch' }"
            >
                <div :class="getColumnClass(element, 0)">
                    <FrontendFragment v-bind="{ elements: element.columns[0], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 1)">
                    <FrontendFragment v-bind="{ elements: element.columns[1], page: props.page }"></FrontendFragment>
                </div>
            </div>

            <div
                class="ce-sidebyside3 ce-sidebyside row"
                v-if="element.type === 'sidebyside3'"
                :style="calculateStyle(element)"
                :class="{ 'cms-stretch': element.config.alignItems === 'stretch' }"
            >
                <div :class="getColumnClass(element, 0)">
                    <FrontendFragment v-bind="{ elements: element.columns[0], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 1)">
                    <FrontendFragment v-bind="{ elements: element.columns[1], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 2)">
                    <FrontendFragment v-bind="{ elements: element.columns[2], page: props.page }"></FrontendFragment>
                </div>
            </div>

            <div
                class="ce-sidebyside4 ce-sidebyside row"
                v-if="element.type === 'sidebyside4'"
                :style="calculateStyle(element)"
                :class="{ 'cms-stretch': element.config.alignItems === 'stretch' }"
            >
                <div :class="getColumnClass(element, 0)">
                    <FrontendFragment v-bind="{ elements: element.columns[0], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 1)">
                    <FrontendFragment v-bind="{ elements: element.columns[1], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 2)">
                    <FrontendFragment v-bind="{ elements: element.columns[2], page: props.page }"></FrontendFragment>
                </div>
                <div :class="getColumnClass(element, 3)">
                    <FrontendFragment v-bind="{ elements: element.columns[3], page: props.page }"></FrontendFragment>
                </div>
            </div>

            <CmsButtonLink
                :class="props.page?.slug != 'page-footer' ? 'btn btn-primary' : 'footer-link'"
                :element="element"
                v-if="element.type === 'button'"
            ></CmsButtonLink>

            <CmsPictureGallery
                v-if="element.type === 'picture-gallery'"
                v-bind="{ element: element }"
            ></CmsPictureGallery>

            <CmsBigBanners v-if="element.type === 'big-banners'" v-bind="{ element: element }"></CmsBigBanners>

            <CmsBigPicture v-if="element.type === 'big-pictures'" v-bind="{ element: element }"></CmsBigPicture>

            <CmsPictureEnum v-if="element.type === 'picture-enum'" v-bind="{ element: element }"></CmsPictureEnum>

            <CmsAccordionEnumeration
                v-if="element.type === 'accordion-enum'"
                v-bind="{ element: element }"
            ></CmsAccordionEnumeration>

            <CmsPictureLinks v-if="element.type === 'picture-links'" v-bind="{ element: element }"></CmsPictureLinks>

            <CmsPictureText v-if="element.type === 'picture-text'" v-bind="{ element }"></CmsPictureText>

            <CmsImageGroup v-if="element.type === 'image-group'" v-bind="{ element }"></CmsImageGroup>

            <CmsPdf v-if="element.type === 'pdf'" v-bind="{ element }"></CmsPdf>

            <CmsYouTube v-if="element.type === 'youtube'" v-bind="{ element }"></CmsYouTube>

            <CmsOnlineForm v-if="element.type === 'onlineform'" v-bind="{ element }"></CmsOnlineForm>

            <CmsCondition v-if="element.type === 'condition'" v-bind="{ element }"></CmsCondition>

            <CmsTimeline v-if="element.type === 'timeline'" v-bind="{ element }"></CmsTimeline>

            <CmsContactForm v-if="element.type === 'contactform'" v-bind="{ element }"></CmsContactForm>

            <CmsLoginForm v-if="element.type === 'loginform'" v-bind="{ element }"></CmsLoginForm>

            <CmsFaq v-if="element.type === 'faq'" v-bind="{ element }"></CmsFaq>

            <CmsChampionshipResults
                v-if="element.type === 'championship-results'"
                v-bind="{ element }"
            ></CmsChampionshipResults>

            <CmsSideNews v-if="element.type === 'side-news'" v-bind="{ element }"></CmsSideNews>
            <CmsRelatedNews v-if="element.type === 'related-news'" v-bind="{ element }"></CmsRelatedNews>
            <CmsNewsOverview v-if="element.type === 'news-overview'" v-bind="{ element }"></CmsNewsOverview>
            <CmsEventOverview v-if="element.type === 'event-overview'" v-bind="{ element }"></CmsEventOverview>
            <CmsLinkList v-if="element.type === 'link-list'" v-bind="{ element }"></CmsLinkList>
            <div v-if="element.type === 'anchor'" :id="element.config.name"></div>
        </div>
    </template>
    <!-- </div> -->
</template>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.cms-container {
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 1.5rem;
    font-size: 16px; /* VB-1198, VB-1268 */
}

.cms-stretch > div > .cms-container {
    height: 100%;
}

.cms-col-stretch > .cms-container {
    height: 100%;
}

.cms-container .cms-container {
    padding: 0;
}

.cms-box {
    --cms-flex-direction: column;
    --cms-flex-direction-md: column;
    height: 100%;
    flex-direction: var(--cms-flex-direction);
    display: flex;

    @include media-breakpoint-up(md) {
        flex-direction: var(--cms-flex-direction-md);
    }

    & > .cms-container {
        margin: 0;
    }
}

.ce-sidebyside.row {
    @include media-breakpoint-up(sm) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

:deep(p:has(table.table)) {
    display: block;
    width: 100%;
    overflow-x: auto;
    // random value that seems to work good enough
    max-width: 70vw;
}
</style>
