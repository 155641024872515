import type { Entity } from "@/vf"
import type {
    ListFieldConfiguration,
    ListFieldGroupConfiguration,
    ListOnClickFn,
    ModuleConfiguration,
    ModuleConfigurationOptions,
    NormalizedCrudViewModelInterface,
    NormalizedListFieldGroupConfiguration,
} from "./types"

import { isVueComponent } from "./util"

export function normalizeListFieldGroups(
    fieldGroups: ListFieldGroupConfiguration[] | undefined | null,
    fields: ListFieldConfiguration[] | undefined | null,
): NormalizedListFieldGroupConfiguration[] {
    if (fieldGroups && fields) {
        throw Error("cannot use both fieldGroups and fields")
    }

    if (!fields) {
        fields = []
    }

    if (!fieldGroups) {
        fieldGroups = [
            {
                name: "default",
                title: "Default",
                fields,
            },
        ]
    }

    return fieldGroups.map(normalizeListFieldGroup).filter(fieldGroup => fieldGroup.fields?.length)
}

export function normalizeListFieldGroup(
    fieldGroup: ListFieldGroupConfiguration,
): NormalizedListFieldGroupConfiguration {
    fieldGroup.fields = fieldGroup.fields.map(field => ({
        defaultHidden: false,
        ...field,
    }))
    return {
        collapseMultiRowsAfter: 3,
        highlightKey: fieldGroup.name,
        ...fieldGroup,
    }
}

export function normalizeShowFieldGroupsHeadRow(
    showFieldGroupsHeadRowUserOption: boolean | undefined,
    fieldGroups: NormalizedListFieldGroupConfiguration[] | undefined,
): boolean {
    if (showFieldGroupsHeadRowUserOption !== undefined) {
        return showFieldGroupsHeadRowUserOption
    }

    if (!fieldGroups) {
        return false
    }

    return fieldGroups.length > 1 || (fieldGroups[0]?.name ?? "default") != "default"
}

export function normalizeShowOpenRowAsTab(
    showOpenRowAsTabUserOption: boolean | undefined,
    fieldGroups: NormalizedListFieldGroupConfiguration[] | undefined,
): boolean {
    if (showOpenRowAsTabUserOption !== undefined) {
        return showOpenRowAsTabUserOption
    }

    if (!fieldGroups) {
        return false
    }

    return fieldGroups.length > 1 || (fieldGroups[0]?.name ?? "default") != "default"
}

export function normalizeListOnClick(
    normalized: Partial<ModuleConfiguration>,
    options: ModuleConfigurationOptions,
): (
    entity: Entity,
    field: string,
    fieldGroup?: NormalizedListFieldGroupConfiguration,
) => NormalizedCrudViewModelInterface | null {
    if (options.list?.onClick === null) {
        return () => null
    }

    const input = options.list?.onClick ?? "show"

    if (typeof input === "function") {
        return (
            entity: Entity,
            field: string,
            fieldGroup?: NormalizedListFieldGroupConfiguration,
            multiRowIndex?: number,
        ): NormalizedCrudViewModelInterface | null => {
            const result = (input as ListOnClickFn)(entity, field, fieldGroup, multiRowIndex)

            if (result === null) {
                return null
            }

            if (typeof result === "string" && ["show", "edit"].includes(result)) {
                return normalized[result as "show" | "edit"]!.page(entity)
            }

            if (typeof result === "string") {
                return {
                    component: result,
                    routePart: result,
                    props: normalized.routeParams!(entity),
                    mode: options.mode ?? "router",
                    rowId: entity?.id,
                }
            }

            if (isVueComponent(result)) {
                return {
                    component: result,
                    props: normalized.routeParams!(entity),
                    mode: options.mode ?? "router",
                    rowId: entity?.id,
                }
            }

            return {
                props: null,
                mode: options.mode ?? "router",
                rowId: entity?.id,
                ...result,
            }
        }
    }

    if (["show", "edit"].includes(input)) {
        // noinspection JSUnusedLocalSymbols
        return (
            entity: Entity,
            //field: string,
            //fieldGroup: NormalizedListFieldGroupConfiguration,
        ): NormalizedCrudViewModelInterface | null => {
            return normalized[input]?.page(entity) ?? null
        }
    }

    throw Error('onClick: only "show" and "edit" allowed as string literals or unknown value for onClick')
}
