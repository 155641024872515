import { arrayNotationToObject, type NestedRecordStringString } from "@/utils/arrayNotationToObject"

export function appendFormDataToFormData(appendTo: FormData, append: FormData, asKey: string) {
    append.forEach((value: FormDataEntryValue, key: string) => {
        const keys: string[] = key.replace("]", "").split("[")
        appendTo.append(asKey + "[" + keys.join("][") + "]", value)
    })
    return appendTo
}

export function formDataToObject(formData: FormData): NestedRecordStringString {
    const dataObject: Record<string, string> = {}
    formData.forEach((value, key) => {
        dataObject[key] = value as string
    })

    return arrayNotationToObject(dataObject)
}
