<script lang="ts" setup>
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    iban?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    bic?: string
    /**
     * Groups: DebitorProfile, Staff.list
     */
    accountOwner?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_ProductGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: ProductGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_IssueGroup {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: edit-translations
     */
    translations?: IssueGroupText[]
    /**
     * Groups: select
     */
    indent?: number
    /**
     * Groups: (translation)
     */
    name?: string
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile, Staff.list
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "AccountInvitationStaffMember" | "AccountInvitationOrganizationMember" | "AccountInvitationOrganizationManager" | "OrganizationDebitorProfile" | "StaffInvitation" | "StaffContract" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default
     */
    issueGroup?: _VTI_TYPE_IssueGroup
    /**
     * Groups: default
     */
    productGroup?: _VTI_TYPE_ProductGroup
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: list
     */
    starred?: boolean
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import { ComMessage } from '@/model/communication/com-message'
import { $datetime } from '@/vf'
import { ref, toRefs } from 'vue'
const props = defineProps<TasksProps<ComMessage>>()
const { task, index } = toRefs(props)
function initials(name: string) {
    return (
        name
            ?.split(" ")
            .map(n => n[0])
            .join("")
            .toUpperCase()
            .substring(0, 2) ?? ""
    )
}
const footerOpen = ref(false)
</script>

<template>
    <div>
        <div class="p-4 d-flex align-items-center">
            <div class="pr-3" v-if="index > 0">
                <i class="fa fa-fw fa-reply fa-rotate-180 fa-2x text-primary"></i>
            </div>
            <div class="pr-3">
                <div class="rounded-circle bg-primary d-flex" style="width: 4rem; height: 4rem">
                    <h1 class="m-auto text-white">{{ initials(task.participantName) }}</h1>
                </div>
            </div>
            <div class="flex-grow-1">
                <div>{{ $datetime(task.createdAt) }}</div>
                <div>
                    {{ task.participantName }} via
                    <span v-if="task.source === 'portal'">My Varsity</span>
                    <span v-if="task.source === 'office'">My Office</span>
                    <span v-if="task.source === 'contact'">Kontaktformular</span>
                    <span v-if="task.source === 'mail'">E-Mail</span>
                </div>
                <div class="small text-danger" v-t="'@communication:internal_message'" v-if="task.internal"></div>
            </div>
            <div v-if="task.attachments?.length">
                <i class="fa fa-fw fa-paperclip fa-2x"></i>
            </div>
        </div>

        <div class="card-body">
            <div style="white-space: pre-line" v-if="!task.isHtml">{{ task.text }}</div>
            <div v-html="task.text" v-else></div>

            <div v-if="task.textFooter">
                <a
                    href="javascript:void(0)"
                    v-t="'@communication:signature'"
                    @click="footerOpen = true"
                    v-if="!footerOpen"
                ></a>
                <div v-if="footerOpen">
                    <div style="white-space: pre-line" v-if="!task.isHtml">{{ task.textFooter }}</div>
                    <div v-html="task.textFooter" v-else></div>
                </div>
            </div>
        </div>
    </div>
</template>
