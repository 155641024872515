<script setup lang="ts">
import { type AccountContact } from "@/model/app/account-contact"
import { useCountriesStore } from "@/stores/useCountryStore"
import { ref, toRefs, watchEffect } from "vue"

const props = withDefaults(
    defineProps<{
        address?: AccountContact
        showAddressLine?: boolean
    }>(),
    {
        showAddressLine: true,
    },
)
const { address } = toRefs(props)

const countries = useCountriesStore()

const countryName = ref<string>()

watchEffect(() => {
    countryName.value = ""

    if (address.value?.country) {
        countries.getName(address.value.country).then(name => {
            countryName.value = name
        })
    }
})
</script>

<template>
    <div v-if="address">
        <div v-if="showAddressLine && address.addressLine">{{ address.addressLine }}</div>
        <div v-if="address.street || address.streetNumber">{{ address.street }} {{ address.streetNumber }}</div>
        <div v-if="address.zip || address.city">{{ address.country }} {{ address.zip }} {{ address.city }}</div>
        <div v-if="countryName">{{ countryName }}</div>
    </div>
</template>
