<script setup lang="ts">
import { useMenuBuilder, type VfMenuItem } from "@/composables/useMenuBuilder"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"

const props = defineProps<{
    item: VfMenuItem
    active: boolean
}>()

const emit = defineEmits<{
    (e: "close"): void
}>()

const { getFirstChildRoutingData } = useMenuBuilder()

const isProduction = import.meta.env.VITE_ENVIRONMENT === "live"
const hideOnProd = ["@App.MyAthletes.index", "@App.Organization.manage", "@Order.PaymentRequest.list"]

function isVisible(item: VfMenuItem) {
    const id = item.id || item.route

    if (!isProduction || !id) {
        return true
    }
    return hideOnProd.indexOf(id) === -1
}

function close() {
    emit("close")
}
</script>
<template>
    <div class="second-level">
        <AnimateIf :if="active">
            <template v-for="child of item.children">
                <template v-if="isVisible(child)">
                    <div v-if="child.label" class="nav-item">
                        <RouterLink :to="getFirstChildRoutingData(child)" class="nav-link" @click="close">
                            <VarsityContentProcessor :content="$t(child.label)"></VarsityContentProcessor>
                        </RouterLink>
                    </div>

                    <div v-if="child.children" class="third-level">
                        <template v-for="subchild of child.children">
                            <div v-if="subchild.label" class="nav-item">
                                <RouterLink :to="getFirstChildRoutingData(subchild)" class="nav-link" @click="close">
                                    <VarsityContentProcessor :content="$t(subchild.label)"></VarsityContentProcessor>
                                </RouterLink>
                            </div>
                            <div class="fourth-level">
                                <template v-for="subSubChild of subchild.children">
                                    <div v-if="subSubChild.label" class="nav-item">
                                        <RouterLink
                                            :to="getFirstChildRoutingData(subSubChild)"
                                            class="nav-link"
                                            @click="close"
                                        >
                                            <VarsityContentProcessor
                                                :content="$t(subSubChild.label)"
                                            ></VarsityContentProcessor>
                                        </RouterLink>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </AnimateIf>
    </div>
</template>

<style scopet>
.second-level {
    margin-right: -0.5rem;
    margin-left: -2rem;
    background-color: var(--primary-dark);
    padding-left: 2.5rem;
}

.third-level {
    margin-right: -0.5rem;
    margin-left: -2rem;
    padding-left: 3rem;
}

.fourth-level {
    margin-left: 1rem;
}
</style>
